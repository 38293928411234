import Box from '@mui/joy/Box';
import type {OrganizationType, OrganizationsTreeRes} from '../../../shared/types';
import type {TreeItem, TreeItemIndex} from 'react-complex-tree';
import {UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider} from 'react-complex-tree';
import {ViewPage} from '../../../layout/ViewPage';
import 'react-complex-tree/lib/style-modern.css';
import {Typography} from '@mui/joy';
import {useRecoilValue} from 'recoil';
import {accountAtom} from '../../../data/atoms';
import {useOrganizationsTree} from '../../../data/queries';
import {useState} from 'react';
import {CustomModal} from '../../../components/CustomModal';
import {AddOrganization} from '../../../components/AddOrganization';
import {CgOrganisation} from 'react-icons/cg';
import {RiTeamFill} from 'react-icons/ri';
import {FaProjectDiagram} from 'react-icons/fa';
import {DiAppstore} from 'react-icons/di';
import {assertNever} from '../../../utils/misc';
import {getOrganizationsListFromOrganizationTree} from '../../../components/OrganizationSelector';

interface TreeItemData {
  label: string;
  onClick?: () => unknown;
}

export function Organigram() {
  const account = useRecoilValue(accountAtom);

  const {data: organizationsTree, refetch: refetchOrganizationTree} = useOrganizationsTree(account);

  const [idParentOfNewOrganization, setIdParentOfNewOrganization] = useState<number | null>(null);

  if(organizationsTree === undefined) {
    return <>loading...</>;
  }

  const organizationItems = getOrganizationsListFromOrganizationTree(organizationsTree);
  const parentOfNewOrganization = idParentOfNewOrganization === null ? null :
    organizationItems.find(oi => oi.id === idParentOfNewOrganization);
  if(parentOfNewOrganization === undefined)
    throw new Error('cannot find parent of new organization');

  const organizationTreeWithNestedRoot: OrganizationsTreeRes = {
    id: 0,
    name: 'root',
    organizationType: 'organization',
    children: [organizationsTree],
  };

  const treeItems = organizationTreeResToTreeNode(setIdParentOfNewOrganization, organizationTreeWithNestedRoot);



  // TODO: implement refresh logic to be executed when the underlying data
  // changes (e.g. the same or a different user has added an organization).
  // the library doesn't do this in the uncontrolled tree environment but
  // might do so in the controlled tree environment. cannot simply set a
  // react element key to dataUpdatedAt of useOrganizationsTree because
  // then the tree would collapse (or assume its default state) whenever
  // the data gets refreshed.
  return <ViewPage title="Organigram">
    <Box>
      <UncontrolledTreeEnvironment<TreeItemData>
        dataProvider={
          new StaticTreeDataProvider(treeItems,
            (item, newName) => ({...item, data: {...item.data, label: newName}}))
        }
        getItemTitle={item => item.data.label}
        viewState={{}}
        onSelectItems={itemIndexes => {
          if(itemIndexes.length !== 1)
            throw new Error('unexpected length of tree item indexes');

          const item = treeItems[itemIndexes[0]];
          if(item === undefined)
            throw new Error('received index of unknown tree item');

          if(item.data.onClick !== undefined) {
            item.data.onClick();
          }
        }}
      >
        <Tree treeId="tree-1" rootItem={organizationTreeWithNestedRoot.id.toString()} treeLabel="Tree Example" />
      </UncontrolledTreeEnvironment>
    </Box>

    {parentOfNewOrganization !== null &&
      <CustomModal
        open={true}
        handleClose={() => setIdParentOfNewOrganization(null)}
        title="Add Organizational Entity"
      >
        <AddOrganization
          idParentOfNewOrganization={parentOfNewOrganization.id}
          typeParentOfNewOrganization={parentOfNewOrganization.organizationType}
          refetchOrganizationTree={refetchOrganizationTree}
        />
      </CustomModal>
    }
  </ViewPage>;
}

function organizationTreeResToTreeNode(setIdParentOfNewOrganization: (arg0: number) => unknown,
  organizationTree: OrganizationsTreeRes) {
  const r: Record<TreeItemIndex, TreeItem<TreeItemData>> = {};

  function addItemsToR(organizationTreeResArr: OrganizationsTreeRes[], provideAddButton: boolean) {
    for(const organizationTreeRes of organizationTreeResArr) {
      const addButtonId = organizationTreeRes.id.toString() + '-add-button';

      const idsChildren = organizationTreeRes.children.map(child => child.id.toString());

      const OrganizationIcon = getOrganizationTypeIcon(organizationTreeRes.organizationType);

      const label = <Typography fontSize="small"><OrganizationIcon /> {organizationTreeRes.name}</Typography>;

      r[organizationTreeRes.id.toString()] = {
        index: organizationTreeRes.id.toString(),
        children: provideAddButton ? [addButtonId, ...idsChildren] : idsChildren,
        data: {
          label: label as unknown as string,
        },
        isFolder: organizationTreeRes.organizationType !== 'app',
      };

      if(provideAddButton && organizationTreeRes.organizationType !== 'app') {
        const addButtonData = <Typography fontSize="small" fontStyle="italic">+ Add</Typography>;

        r[addButtonId] = {
          index: addButtonId,
          children: [],
          data: {
            // The JS part supports arbitrary data.
            // There doesn't seem to be an intended way of adding icons or styling.
            label: addButtonData as unknown as string,
            onClick: () => setIdParentOfNewOrganization(organizationTreeRes.id),
          },
          isFolder: false,
        };
      }

      addItemsToR(organizationTreeRes.children, true);
    }
  }

  addItemsToR([organizationTree], false);

  return r;
}

export function getOrganizationTypeIcon(organizationType: OrganizationType) {
  switch(organizationType) {
    case 'organization':
      return CgOrganisation;
    case 'team':
      return RiTeamFill;
    case 'project':
      return FaProjectDiagram;
    case 'app':
      return DiAppstore;
    default:
      assertNever(organizationType);
  }
}
