import {Button} from '@mui/joy';
import {endpoints} from '../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import type {WebSessionAuthTokenRes} from '../shared/types';
import {accountAtom, newNotificationAtom} from '../data/atoms';
import {getErrorMessage} from '../errors';
import {queryApi} from '../data/queries';

interface Props {
  deviceRegistrationId: string;
  finalize: () => unknown;
}

export function AddDevice({deviceRegistrationId, finalize}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  async function addDevice() {
    const url = endpoints.putDevice.replace('{deviceId}', deviceRegistrationId);

    try {
      await queryApi(url, account, 'put');
      setNewNotification('Device has been added.');
      finalize();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Button
      onClick={addDevice}
    >
      Confirm Adding Device
    </Button>
  </>;
}
