import {useRecoilValue} from 'recoil';
import {useOngoingScans} from '../data/queries';
import type {WebSessionAuthTokenRes} from '../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../data/atoms';
// @ts-expect-error There does not seem to be a more perfect library than this one (small, no further dependencies, no weird behavior, easy to use, uses the html time tag), and given its limited use, I think no types is fine. The only somewhat viable alternative I've found that does have types is: @yaireo/relative-time
import RelativeTime from 'react-relative-time';
import {List, ListItem, Typography} from '@mui/joy';
import {getOrganizationIdsFromOrganizationItem} from '../utils/organizationFiltering';

export function OngoingScansHint() {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);

  const {data: ongoingScans} = useOngoingScans(account,
    getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem));

  if(ongoingScans === undefined || ongoingScans.length === 0)
    return <></>;

  return <div style={{padding: 10, paddingBottom: 2, backgroundColor: '#e7d771', borderRadius: 10}}>
    <Typography fontWeight='bold'>
      There are ongoing scans:
    </Typography>
    <List sx={{ml: 5}}>
      {ongoingScans.map((ongoingScan, i) =>
        <ListItem key={i} sx={{display: 'list-item', listStyleType: 'disc', pl: 1}}>
          {`${ongoingScan.scannableResourceName} started `} <RelativeTime value={ongoingScan.dateInMs} /> {`(triggered by ${ongoingScan.trigger})`}
        </ListItem>)}
    </List>
  </div>;
}
