import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

interface Props<T extends string | number> {
  title: string;
  onChange: (optionId: T | null) => unknown;
  options: OptionProps<T>[];
}

type OptionProps<T extends string | number> = {
  id: T;
  label?: string;
};

export function Filter<T extends string | number>({title, options, onChange}: Props<T>) {
  return <>
    <FormControl size="sm">
      <FormLabel>{title}</FormLabel>
      <Select size="sm" defaultValue="" onChange={(_, stringKey) => {
        if(stringKey === null)
          throw new Error('string key is null unexpectedly');

        if(stringKey === '') {
          onChange(null);
          return;
        }

        const key = parseInt(stringKey);
        const chosenOption = options[key];
        onChange(chosenOption.id);
      }}>
        <Option value="">All</Option>
        {options.map((option, i) =>
          <Option
            key={i}
            value={i.toString()}
          >
            {option.label ?? option.id}
          </Option>
        )}
      </Select>
    </FormControl>
  </>;
}
