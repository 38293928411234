import {ViewPage} from '../../../layout/ViewPage';

export function Dashboard() {
  return <ViewPage title="Dashboard">
    <img
      style={{objectFit: 'contain'}}
      src="/dashboard_mock.png"
    />
  </ViewPage>;
}
