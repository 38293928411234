import type {ScannableResourceRes} from '../shared/types';
import {CoverageChip} from './CoverageChip';

type CoverageType = 'code' | 'secrets' | 'dependencies' | 'license' | 'backup';

interface Props {
  scannableResource: ScannableResourceRes;
  coverageTypesToDisplay: CoverageType[];
}

export function Coverage({scannableResource, coverageTypesToDisplay}: Props) {
  const counts = new Map<CoverageType, number>();

  function getCount(coverageType: CoverageType) {
    return counts.get(coverageType) ?? 0;
  }

  function increaseCount(coverageType: CoverageType) {
    counts.set(coverageType, getCount(coverageType) + 1);
  }

  if(scannableResource.sonarcloudProject !== null) {
    increaseCount('code');
  }

  if(scannableResource.githubRepository !== null) {
    const githubRepository = scannableResource.githubRepository;

    if((githubRepository.periodicScanBranchName !== '' && githubRepository.periodicScanFrequencyInMs !== 0) ||
      (githubRepository.scanOnPushEnabled && githubRepository.scanOnPushBranchNameRegex !== '')) {
      // semgrep
      increaseCount('code');
      increaseCount('secrets');

      // gitleaks
      increaseCount('secrets');

      // dependencycheck
      increaseCount('dependencies');
    }
  }

  if(scannableResource.sonatypeApplication !== null) {
    increaseCount('dependencies');
    increaseCount('license');
  }

  if(scannableResource.mongodbatlasCluster !== null) {
    // TODO: determine whether backup is enabled / actually working
    increaseCount('backup');
  }

  return <>
    {coverageTypesToDisplay.map((coverageType, i) =>
      <CoverageChip key={i} label={coverageType} count={getCount(coverageType)} />
    )}
  </>;
}
