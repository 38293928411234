import {Box, Button, Input} from '@mui/joy';
import {useState} from 'react';
import {queryApi} from '../../../data/queries';
import type {SonatypeTokenPutReq, WebSessionAuthTokenRes} from '../../../shared/types';
import {endpoints} from '../../../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {getErrorMessage} from '../../../errors';

interface Props {
  onCompletion: () => Promise<unknown>;
}

export function SonatypeAuthentication({onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [sonatypeApiUrl, setSonatypeApiUrl] = useState('');
  const [sonatypeUsercode, setSonatypeUsercode] = useState('');
  const [sonatypePasscode, setSonatypePasscode] = useState('');

  async function authenticate() {
    const data = {
      sonatypeApiUrl,
      sonatypeUsercode,
      sonatypePasscode,
    } satisfies SonatypeTokenPutReq;
    try {
      await queryApi(endpoints.putSonatypeToken, account, 'put', data);
      await onCompletion();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  // TODO: beschreiben, wo man als nutzer die sonatype-api-zugangsdaten herbekommt
  return <>
    <Box>
      To import Sonatype applications, please head to ...
    </Box>
    <Box>
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="sonatype API URL"
        value={sonatypeApiUrl}
        onChange={e => setSonatypeApiUrl(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="sonatype usercode"
        value={sonatypeUsercode}
        onChange={e => setSonatypeUsercode(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="sonatype passcode"
        value={sonatypePasscode}
        onChange={e => setSonatypePasscode(e.target.value)}
      />
      <Button
        onClick={authenticate}
        disabled={sonatypeApiUrl.length === 0 || sonatypeUsercode.length === 0 || sonatypePasscode.length === 0}
      >
        Authenticate
      </Button>
    </Box>
  </>;
}
