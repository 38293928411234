import {Button, Input, Typography} from '@mui/joy';
import {useState} from 'react';
import type {WebSessionAuthTokenRes, PolicyPostReq} from '../shared/types';
import {endpoints} from '../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../data/atoms';
import {getErrorMessage} from '../errors';
import {queryApi} from '../data/queries';
import {OrganizationSelector} from './OrganizationSelector';

interface Props {
  onCompletion: () => unknown;
}

export function AddPolicy({onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const [name, setName] = useState('');

  async function addPolicy() {
    const data = {
      name,
      organizationId: idSelectedOrganization,
    } satisfies PolicyPostReq;

    try {
      await queryApi(endpoints.postPolicies, account, 'post', data);
      setNewNotification('Policy has been created.');
      await onCompletion();

      setName('');
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Typography sx={{marginBottom: 2}}>
      Please choose a name for the new policy.
    </Typography>
    <Input
      sx={{marginBottom: 2}}
      placeholder="name"
      value={name}
      onChange={e => setName(e.target.value)}
    />
    <OrganizationSelector
      limitToGloballySelectedOrganizationItem={true}
      onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
      organizationTypeFilter={['organization', 'team', 'project', 'app']}
    />
    <Button
      disabled={name === ''}
      onClick={addPolicy}
    >
      Submit
    </Button>
  </>;
}

