import {CodeBlock, dracula} from 'react-code-blocks';
import {githubWorkflow} from '../shared/ciIntegration/githubWorkflow';
import {msAzurePipeline} from '../shared/ciIntegration/msAzurePipeline';
import {Button, Typography} from '@mui/joy';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, clipboardAtom, newNotificationAtom} from '../data/atoms';
import {endpoints} from '../data/endpoints';
import type {WebSessionAuthTokenRes, PrCreationRes} from '../shared/types';
import {getErrorMessage} from '../errors';
import {getRepositoryType} from '../utils/repositoryRemoteUrls';
import {otherCi} from '../shared/ciIntegration/otherCi';
import {queryApi} from '../data/queries';

interface Props {
  remoteUrl: string;
  githubRepositoryId?: number;
  ciToken: string;
}

export function CiIntegrationInformation({remoteUrl, githubRepositoryId, ciToken}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setClipboard = useSetRecoilState(clipboardAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const repositoryType = getRepositoryType(remoteUrl);

  async function installSecguroWorkflow(githubRepositoryId: number) {
    const url = endpoints.putGithubRepositorySecguroWorkflowInstallation
      .replace('{githubRepositoryId}', githubRepositoryId.toString());
    try {
      const prCreationRes = await queryApi<PrCreationRes>(url, account, 'post');
      setNewNotification(<>PR has been created: <a target="_blank" href={prCreationRes.prUrl}>{prCreationRes.prUrl}</a></>);
    } catch(e) {
      setNewNotification('Failed to install secguro workflow: ' + getErrorMessage(e));
    }
  }

  return <>
    {repositoryType === 'github' &&
      <>
        <h3>GitHub Workflow</h3>
        <div style={{display: 'flex'}}>
          <div style={{width: '70%'}}>
            <CodeBlock
              text={githubWorkflow()}
              language="yaml"
              theme={dracula}
            />
          </div>
          <div style={{marginLeft: 20}}>
            <div style={{marginBottom: 10}}>
              <Typography>
                Before installing the workflow, please define github secret SECGURO_CI_TOKEN to be:
              </Typography>
              <Typography fontSize="medium" fontFamily="Monospace">
                {ciToken}
              </Typography>
            </div>
            <div style={{marginBottom: 10}}>
              <Button
                onClick={() => setClipboard(ciToken)}
              >
                Copy CI Token to Clipboard
              </Button>
            </div>
            <div style={{marginBottom: 50}}>
              <Typography>
                To do so, go to your github repository and navigate to
                Settings → Secrets and variables → Actions → New repository secret
              </Typography>
              <Typography>
                If the feature of multiple sets of environment variables is available in the repository,
                please make sure that the environment name matches the "environment:" line. If this
                feature is not available in the repository, the value stated in this line does not
                matter and you may even delete this line.
              </Typography>
            </div>
            <div style={{marginBottom: 10}}>
              <Button
                onClick={() => setClipboard(githubWorkflow())}
              >
                Copy Code to Clipboard
              </Button>
            </div>
            {githubRepositoryId !== undefined &&
              <div style={{marginBottom: 10}}>
                <Button
                  onClick={() => installSecguroWorkflow(githubRepositoryId).catch(console.error)}
                >
                  Install via PR
                </Button>
              </div>
            }
          </div>
        </div>
      </>
    }

    {
      repositoryType === 'msAzure' &&
      <>
        <h3>MS Azure Pipeline</h3>
        <div style={{display: 'flex'}}>
          <div style={{width: '70%'}}>
            <CodeBlock
              text={msAzurePipeline()}
              language="yaml"
              theme={dracula}
            />
          </div>
          <div style={{marginLeft: 20}}>
            <div style={{marginBottom: 10}}>
              <Typography>
                Before installing the pipeline, please define group secret SECGURO_CI_TOKEN to be:
              </Typography>
              <Typography fontSize="medium" fontFamily="Monospace">
                {ciToken}
              </Typography>
            </div>
            <div style={{marginBottom: 10}}>
              <Button
                onClick={() => setClipboard(ciToken)}
              >
                Copy CI Token to Clipboard
              </Button>
            </div>
            <div style={{marginBottom: 50}}>
              <Typography>
                To do so, go to your Azure devops repository and perform the following actions:
                Library → +Variable Group → choose name "default_environment" → add → [enter variable name and token] → press lock symbol in row → save
              </Typography>
              <Typography>
                Subsequently, run the pipeline manually and next to "This pipeline needs permission to access a resource before this run can continue to Build and push stage", click: "view" → "Permit" → "Permit"
              </Typography>
            </div>
            <div style={{marginBottom: 10}}>
              <Button
                onClick={() => setClipboard(msAzurePipeline())}
              >
                Copy Code to Clipboard
              </Button>
            </div>
          </div>
        </div>
      </>
    }

    {
      repositoryType === 'other' &&
      <>
        <h3>Other CI</h3>
        <div style={{display: 'flex'}}>
          <div style={{width: '70%'}}>
            <CodeBlock
              text={otherCi(ciToken)}
              language="yaml"
              theme={dracula}
            />
          </div>
          <div style={{marginLeft: 20}}>
            <div style={{marginBottom: 50}}>
              <Typography>
                If you need to check this command into your git repository for your CI:
                Note that it is recommended, to use a secrets manager instead of
                checking the secguro CI token into your repository.
              </Typography>
            </div>
            <div style={{marginBottom: 10}}>
              <Button
                onClick={() => setClipboard(otherCi(ciToken))}
              >
                Copy Code to Clipboard
              </Button>
            </div>
          </div>
        </div>
      </>
    }
  </>;
}

