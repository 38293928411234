import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import {MdKeyboardArrowRight} from 'react-icons/md';
import {MdKeyboardArrowLeft} from 'react-icons/md';
import {range} from '../../utils/misc';
import {getNumberOfPages} from '../../utils/pagination';

interface Props {
  pageNumber: number;
  pageSize: number;
  totalNumberOfItems: number;
  setPageNumber: (n: number) => unknown;
}

export function PaginationBar({pageNumber, pageSize, totalNumberOfItems, setPageNumber}: Props) {
  return <>
    <Box
      className="Pagination-laptopUp"
      sx={{
        pt: 2,
        gap: 1,
        [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'},
        display: {
          xs: 'none',
          md: 'flex',
        },
      }}
    >
      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        startDecorator={<MdKeyboardArrowLeft />}
        onClick={() => setPageNumber(pageNumber - 1)}
        disabled={pageNumber === 1}
      >
        Previous
      </Button>

      <Box sx={{flex: 1}} />
      {getPaginationSymbols(pageNumber, getNumberOfPages(pageSize, totalNumberOfItems))
        .map((paginationSymbol, i) => (
          <IconButton
            key={i}
            size="sm"
            color="neutral"
            variant={paginationSymbol.clickable ? 'outlined' : 'plain'}
            style={{backgroundColor: paginationSymbol.pageNumber === pageNumber ? 'gray' : undefined}}
            onClick={() => {
              if(!paginationSymbol.clickable)
                return;

              setPageNumber(paginationSymbol.pageNumber);
            }}
          >
            {paginationSymbol.pageNumber}
          </IconButton>
        ))}
      <Box sx={{flex: 1}} />

      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        endDecorator={<MdKeyboardArrowRight />}
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber === getNumberOfPages(pageSize, totalNumberOfItems)}
      >
        Next
      </Button>
    </Box>
  </>;
}

type PaginationSymbol =
  {
    pageNumber: number;
    clickable: true;
  } |
  {
    pageNumber: number | string;
    clickable: false;
  };

function getPaginationSymbols(pageNumber: number, totalNumberOfPages: number): PaginationSymbol[] {
  const numbersOnEitherSide = 3;
  const numberOfBufferPages = 2;

  if(totalNumberOfPages <= 2 * numbersOnEitherSide + 1) {
    return range(totalNumberOfPages).map(i => ({
      pageNumber: i + 1,
      clickable: true,
    }));
  }

  const res: PaginationSymbol[] = [];

  for(let i = 1; i <= totalNumberOfPages; i++) {
    if(res.length > 0 && res[res.length - 1].clickable && res[res.length - 1].pageNumber !== i - 1) {
      res.push({
        pageNumber: '…',
        clickable: false,
      });
    }

    if(i <= numbersOnEitherSide || i >= totalNumberOfPages - numbersOnEitherSide + 1 ||
      Math.abs(i - pageNumber) <= numberOfBufferPages) {
      res.push({
        pageNumber: i,
        clickable: true,
      });
    }
  }

  return res;
}
