import {
  atom,
} from 'recoil';

import type {
  WebSessionAuthTokenRes
} from '../shared/types';
import type {OrganizationsListItem} from '../components/OrganizationSelector';

const LS_ACCOUNT_DATA = 'accountDataSecguroWebapp';

type SetSelf<T> = (arg0: T) => void;
type OnSet<T> = (arg0: (a0: T) => void) => void;

export const clipboardAtom = atom<string | null>({
  key: 'clipboard',
  default: null,
});

export const newNotificationAtom = atom<string | JSX.Element | null>({
  key: 'newNotification',
  default: null,
});

const syncAccount = () => ({trigger, setSelf, onSet}:
  {trigger: string; setSelf: SetSelf<WebSessionAuthTokenRes | null>; onSet: OnSet<WebSessionAuthTokenRes | null>}) => {

  if(trigger === 'get') {
    const accountDataAsStr = localStorage.getItem(LS_ACCOUNT_DATA);
    if(accountDataAsStr !== null) {
      const accountData = JSON.parse(accountDataAsStr) as WebSessionAuthTokenRes;
      setSelf(accountData);
    }
  }

  onSet((accountData: WebSessionAuthTokenRes | null) => {
    localStorage.setItem(LS_ACCOUNT_DATA, JSON.stringify(accountData));
  });
};

export const accountAtom = atom<WebSessionAuthTokenRes | null>({
  key: 'account',
  default: null,
  effects_UNSTABLE: [
    syncAccount()
  ]
});

export const globallySelectedOrganizationItemAtom = atom<OrganizationsListItem | null>({
  key: 'globallySelectedOrganizationItem',
  default: null,
});
