import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import {useNavigate} from 'react-router-dom';
import {config} from '../../config';
import {Tooltip} from '@mui/joy';
import PolicyIcon from '@mui/icons-material/Policy';
import {IoMdAnalytics} from 'react-icons/io';
import {RiTaskFill} from 'react-icons/ri';
import {FaUserGroup} from 'react-icons/fa6';
import {IoPersonSharp} from 'react-icons/io5';
import {FaCloud} from 'react-icons/fa';
import {GrConfigure} from 'react-icons/gr';
import {FaTerminal} from 'react-icons/fa';
import {SiCyberdefenders} from 'react-icons/si';
import {FaPencilRuler} from 'react-icons/fa';
import type {SidebarInformationRes} from '../../shared/types';
import {SidebarListPartition} from './SidebarListPartition';

interface Props {
  sidebarInformation: SidebarInformationRes | undefined;
}

export function SidebarUpperListPartition({sidebarInformation}: Props) {
  const navigate = useNavigate();

  return <>
    <SidebarListPartition>
      <ListItem>
        <ListItemButton onClick={() => navigate(config.urls.pages.root)}>
          <IoMdAnalytics />
          <ListItemContent>
            <Typography level="title-sm">Overview</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem >

      <ListItem nested>
        <ListItemButton>
          <RiTaskFill />
          <ListItemContent>
            <Typography level="title-sm">Tasks</Typography>
          </ListItemContent>
        </ListItemButton>
        <List sx={{gap: 0.5}}>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.tasks)}>
              <IoPersonSharp />
              <ListItemContent>
                <Typography level="title-sm">My Tasks</Typography>
              </ListItemContent>
              <Chip size="sm" sx={{backgroundColor: config.colors.severity.error}}>
                2
              </Chip>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.tasks)}>
              <FaUserGroup />
              <ListItemContent>
                <Typography level="title-sm">All Tasks</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>

      <ListItem>
        <ListItemButton onClick={() => navigate(config.urls.pages.policyViolations)}>
          <PolicyIcon />
          <ListItemContent>
            <Typography level="title-sm">Compliance</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem nested>
        <ListItemButton>
          <GrConfigure />
          <ListItemContent>
            <Typography level="title-sm">Findings</Typography>
          </ListItemContent>
        </ListItemButton>
        <List sx={{gap: 0.5}}>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.root)}>
              <FaCloud />
              <ListItemContent>
                <Typography level="title-sm">Resource Configs</Typography>
              </ListItemContent>
              <Chip size="sm" sx={{backgroundColor: config.colors.severity.error}}>
                2
              </Chip>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.findings)}>
              <FaTerminal />
              <ListItemContent>
                <Typography level="title-sm">Software</Typography>
              </ListItemContent>
              {sidebarInformation !== undefined &&
                <>
                  <Tooltip title="error">
                    <Chip size="sm" sx={{backgroundColor: config.colors.severity.error}}>
                      {sidebarInformation.numberOfFindingsSeverityError}
                    </Chip>
                  </Tooltip>
                </>
              }
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.root)}>
              <SiCyberdefenders />
              <ListItemContent>
                <Typography level="title-sm">Attacts</Typography>
              </ListItemContent>
              <Chip size="sm" sx={{backgroundColor: config.colors.severity.error}}>
                2
              </Chip>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.root)}>
              <FaCloud />
              <ListItemContent>
                <Typography level="title-sm">Employees</Typography>
              </ListItemContent>
              <Chip size="sm" sx={{backgroundColor: config.colors.severity.error}}>
                2
              </Chip>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigate(config.urls.pages.ruleViolations)}>
          <FaPencilRuler />
          <ListItemContent>
            <Typography level="title-sm">Rule Violations</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>
    </SidebarListPartition>
  </>;
}
