import {Box, Button, Input} from '@mui/joy';
import {useState} from 'react';
import {queryApi} from '../../../data/queries';
import type {AzureTokenPutReq, WebSessionAuthTokenRes} from '../../../shared/types';
import {endpoints} from '../../../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {getErrorMessage} from '../../../errors';

interface Props {
  onCompletion: () => Promise<unknown>;
}

export function AzureAuthentication({onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [azureTenant, setAzureTenant] = useState('');
  const [azureClientId, setAzureClientId] = useState('');
  const [azureClientSecret, setAzureClientSecret] = useState('');

  async function authenticate() {
    const data = {
      azureTenant,
      azureClientId,
      azureClientSecret,
    } satisfies AzureTokenPutReq;
    try {
      await queryApi(endpoints.putAzureToken, account, 'put', data);
      await onCompletion();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  // TODO: @Matthias Beschreibung einfügen
  return <>
    <Box>
      To import Azure subscriptions, please [...]
    </Box>
    <Box>
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="azure tenant"
        value={azureTenant}
        onChange={e => setAzureTenant(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="azure client ID"
        value={azureClientId}
        onChange={e => setAzureClientId(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="azure client secret"
        value={azureClientSecret}
        onChange={e => setAzureClientSecret(e.target.value)}
      />
      <Button
        onClick={authenticate}
        disabled={azureTenant.length === 0 || azureClientId.length === 0 || azureClientSecret.length === 0}
      >
        Authenticate
      </Button>
    </Box>
  </>;
}
