import {useSetRecoilState} from 'recoil';
import {clipboardAtom} from '../data/atoms';

interface Props {
  textToDisplay: string;
  textToCopy?: string;
}

export function TextCopyableByClick({textToDisplay, textToCopy}: Props) {
  const setClipboard = useSetRecoilState(clipboardAtom);

  return <div onClick={() => setClipboard(textToCopy ?? textToDisplay)}>
    {textToDisplay}
  </div>;
}
