import {useRecoilValue} from 'recoil';
import {accountAtom} from './data/atoms';
import type {WebSessionAuthTokenRes} from './shared/types';
import {LoginAndRegistration} from './views/loggedOut/auth/LoginAndRegistration';
import {Layout} from './layout/Layout';

export function ProtectedRoute({children}: {children: JSX.Element}): JSX.Element {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);

  return account === null ?
    <LoginAndRegistration /> :
    <Layout>
      {children}
    </Layout>;
}
