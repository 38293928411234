import {envConfig} from '../envConfig';
import {API_V1} from '../shared/types';

const SERVER_ENDPOINT = envConfig.API_URL;

export const GITHUB_APP_NAME = envConfig.GITHUB_APP_NAME;
export const GITHUB_OAUTH_CLIENT_ID = envConfig.GITHUB_OAUTH_CLIENT_ID;

export const endpoints = {
  getSidebarInformation: SERVER_ENDPOINT + '/sidebarInformation',
  postRootOrganization: SERVER_ENDPOINT + '/rootOrganizations',
  postOrganizations: SERVER_ENDPOINT + '/organizations',
  getOrganizationsTree: SERVER_ENDPOINT + '/organizationsTree',
  getUser: SERVER_ENDPOINT + '/user',
  putSonarcloudToken: SERVER_ENDPOINT + '/user/sonarcloudToken',
  putSonatypeToken: SERVER_ENDPOINT + '/user/sonatypeToken',
  putAzureToken: SERVER_ENDPOINT + '/user/azureToken',
  putMongodbatlasToken: SERVER_ENDPOINT + '/user/mongodbatlasToken',
  getUsers: SERVER_ENDPOINT + '/users',
  getUsersMinimal: SERVER_ENDPOINT + '/usersMinimal',
  postUsers: SERVER_ENDPOINT + '/users',
  postUserVerification: SERVER_ENDPOINT + '/users/verifications',
  postWebSessionAuthToken: SERVER_ENDPOINT + '/../auth/webSessionAuthTokens',
  getAccounts: SERVER_ENDPOINT + '/accounts',
  getDevices: SERVER_ENDPOINT + '/devices',
  putDevice: SERVER_ENDPOINT + '/devices/{deviceId}',
  getOngoingScans: SERVER_ENDPOINT + '/ongoingScans',
  getScans: SERVER_ENDPOINT + '/scannableResources/{scannableResourceId}/scans',
  getRules: SERVER_ENDPOINT + '/rules',
  getRuleViolations: SERVER_ENDPOINT + '/ruleViolations',
  getPredefinedPolicies: SERVER_ENDPOINT + '/predefinedPolicies',
  getPolicies: SERVER_ENDPOINT + '/policies',
  getPolicyViolations: SERVER_ENDPOINT + '/policyViolations',
  postImportPredefinedPolicy: SERVER_ENDPOINT + '/importPredefinedPolicy',
  postPolicies: SERVER_ENDPOINT + '/policies',
  postPolicyExemptions: SERVER_ENDPOINT + '/policyExemptions',
  deletePolicyExemption: SERVER_ENDPOINT + '/policyExemptions/{id}',
  putPolicyRule: SERVER_ENDPOINT + '/policies/{policyId}/rules/{ruleId}',
  deletePolicyRule: SERVER_ENDPOINT + '/policies/{policyId}/rules/{ruleId}',
  getFindings: SERVER_ENDPOINT + '/findings',
  getVisibleRepositories: SERVER_ENDPOINT + '/visibleRepositories',
  getSonarcloudVisibleScannableResources: SERVER_ENDPOINT + '/sonarcloudVisibleScannableResources',
  getSonatypeVisibleScannableResources: SERVER_ENDPOINT + '/sonatypeVisibleScannableResources',
  getAzureVisibleScannableResources: SERVER_ENDPOINT + '/azureVisibleScannableResources',
  getMongodbatlasVisibleScannableResources: SERVER_ENDPOINT + '/mongodbatlasVisibleScannableResources',
  getScannableResources: SERVER_ENDPOINT + '/scannableResources',
  getScannableResourcesMinimal: SERVER_ENDPOINT + '/scannableResourcesMinimal',
  postScannableResources: SERVER_ENDPOINT + '/scannableResources',
  postGithubRepository: SERVER_ENDPOINT + '/githubRepositories',
  putGithubRepository: SERVER_ENDPOINT + '/githubRepositories/{githubRepositoryId}',
  postSonarcloudProject: SERVER_ENDPOINT + '/sonarcloudProjects',
  postSonatypeApplication: SERVER_ENDPOINT + '/sonatypeApplications',
  postAzureResource: SERVER_ENDPOINT + '/azureResources',
  postMongodbatlasCluster: SERVER_ENDPOINT + '/mongodbatlasClusters',
  putGithubRepositorySecguroWorkflowInstallation: SERVER_ENDPOINT + '/githubRepositories/{githubRepositoryId}/secguroWorkflowInstallation',
  getFindingFix: SERVER_ENDPOINT + '/findings/{findingId}/fixes',
  postFindingFixAcceptance: SERVER_ENDPOINT + '/findings/{findingId}/fixAcceptances',
  putFindingAssignee: SERVER_ENDPOINT + '/findings/{findingId}/assignees',
  getIntegrations: SERVER_ENDPOINT + '/integrations',
  postGithubOAuthState: SERVER_ENDPOINT + '/githubOAuthState',
};

export const DEFAULT_HEADER = {
  'accept': API_V1,
  'content-type': 'application/json',
};
