import {Table} from '../../../components/table/Table';
import {usePolicyViolations} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {WebSessionAuthTokenRes, PolicyViolationRes} from '../../../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../../../data/atoms';
import type {RowType} from '../../../components/table/ActualTable';
import {useEffect, useState} from 'react';
import {Chip, Tooltip, Typography} from '@mui/joy';
import {formatOrganizationName, formatRuleName} from '../../../utils/textFormatting';
import {ViewPage} from '../../../layout/ViewPage';
import CheckIcon from '@mui/icons-material/Check';
import {getOrganizationIdsFromOrganizationItem} from '../../../utils/organizationFiltering';
import {config} from '../../../config';

export function PolicyViolations() {
  const [pageNumber, setPageNumber] = useState(1);

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const {data: policyViolationsPage, refetch: refetchPolicyViolations} = usePolicyViolations(account,
    getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem), pageNumber);

  useEffect(() => {
    refetchPolicyViolations().catch(console.error);
  }, [globallySelectedOrganizationItem, pageNumber]);

  return <ViewPage title="Compliance">
    <Table
      columnTitles={['id', 'Name', 'Organization', 'Exempt Organizations', 'Violated Rules', 'Violating Projects']}
      rows={(policyViolationsPage?.items ?? []).map(policy => getPolicyRow(policy))}
      pagination={
        {
          pageNumber,
          pageSize: policyViolationsPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: policyViolationsPage?.totalNumberOfItems ?? 0,
        }
      }
    />
  </ViewPage>;
}

function getPolicyRow(policyViolation: PolicyViolationRes): RowType {
  const policyExemptions = <>
    {policyViolation.policyExemptions.map((policyExemption, i) =>
      <Tooltip key={i} title={policyExemption.organizationName}>
        <Chip sx={{backgroundColor: config.colors.chips.organization, margin: 0.5}}>
          {formatOrganizationName(policyExemption.organizationName)}
        </Chip>
      </Tooltip>
    )}
  </>;

  const rules = <>
    {policyViolation.rules.map((rule, i) =>
      <Tooltip title={rule.name}>
        <Chip key={i} sx={{backgroundColor: 'cyan', margin: 0.5}}>
          {formatRuleName(rule.name)}
        </Chip>
      </Tooltip>
    )}
  </>;

  const violatingProjects = policyViolation.voilatingOrganizations.length === 0 ?
    <Typography>
      <CheckIcon /> No violations
    </Typography> :
    <>
      {policyViolation.voilatingOrganizations.map((organizationName, i) =>
        <Tooltip title={organizationName}>
          <Chip key={i} sx={{backgroundColor: config.colors.chips.organization, margin: 0.5}}>
            {formatOrganizationName(organizationName)}
          </Chip>
        </Tooltip>
      )}
    </>;

  return [
    policyViolation.id,
    policyViolation.name,
    policyViolation.organizationName,
    policyExemptions,
    rules,
    violatingProjects,
  ];
}
