import {useRecoilState} from 'recoil';
import {clipboardAtom, newNotificationAtom} from './data/atoms';
import {useEffect, useState} from 'react';
import {copyToClipboard} from './utils/clipboard';
import {Snackbar} from '@mui/joy';
import {config} from './config';

export function Notifications() {
  const [clipboard, setClipboard] = useRecoilState(clipboardAtom);
  const [newNotification, setNewNotification] = useRecoilState(newNotificationAtom);
  const [notifications, setNotifications] = useState<(string | JSX.Element)[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if(clipboard === null)
        return;

      const copiedSuccessfully = await copyToClipboard(clipboard);
      if(copiedSuccessfully) {
        setNotifications(['Copied to clipboard']);
      } else {
        setNotifications(['Failed to copy to clipboard']);
      }

      setClipboard(null);
    })().catch(console.error);
  }, [clipboard]);

  useEffect(() => {
    setSnackbarOpen(notifications.length > 0);
  }, [snackbarOpen, notifications]);

  useEffect(() => {
    if(newNotification === null)
      return;

    setNotifications([...notifications, newNotification]);
    setNewNotification(null);
  }, [newNotification]);

  return <>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={config.notifications.timeoutInMs}
      onClose={() => {setNotifications(notifications.slice(1)); setSnackbarOpen(false);}}
    >
      {notifications[0]}
    </Snackbar>
  </>;
}
