import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import type {WebSessionAuthTokenRes, FindingFixAcceptancePostReq, FindingFixRes, FindingRes, FindingAssigneePutReq, PrCreationRes} from '../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom, newNotificationAtom} from '../data/atoms';
import {queryApi, useSidebarInformation, useUsersMinimal} from '../data/queries';
import {useEffect, useState} from 'react';
import {Option, Select, Textarea} from '@mui/joy';
import {endpoints} from '../data/endpoints';
import ReactDiffViewer from 'react-diff-viewer-continued';
import {getErrorMessage} from '../errors';
import {getOrganizationIdsFromOrganizationItem} from '../utils/organizationFiltering';

interface Props {
  finding: FindingRes;
  setModalWide: (arg0: boolean) => unknown;
}

export function FindingDetails({finding, setModalWide}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  // TODO: decide who can be assigned a finding
  const {data: usersMinimal} = useUsersMinimal(account,
    getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem));
  const {refetch: refetchSidebarInformation} = useSidebarInformation(account);

  const [fix, setFix] = useState<FindingFixRes | null>(null);
  const [additionalHint, setAdditionalHint] = useState('');
  const [fixPr, setFixPr] = useState<PrCreationRes | null>(null);

  useEffect(() => {
    setModalWide(fix !== null);
  }, [fix]);

  async function loadFix(findingId: number) {
    const url = endpoints.getFindingFix.replace('{findingId}', findingId.toString());
    const data = {additionalHint};
    setNewNotification('Fix is being requested...');
    try {
      const receivedFix = await queryApi<FindingFixRes>(url, account, 'get', data);
      setFix(receivedFix);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  async function submitFixAcceptance(findingId: number, findingFix: FindingFixRes) {
    const data = {
      githubRepositoryId: findingFix.githubRepositoryId,
      filePath: findingFix.filePath,
      oldFileContentBlobSha: findingFix.oldFileContentBlobSha,
      newContent: findingFix.newContent,
    } satisfies FindingFixAcceptancePostReq;
    const url = endpoints.postFindingFixAcceptance.replace('{findingId}', findingId.toString());
    try {
      const prCreationRes = await queryApi<PrCreationRes>(url, account, 'post', data);
      setFixPr(prCreationRes);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  async function putAssignee(findingId: number, assigneeUserId: number | null) {
    const data = {
      assigneeUserId: assigneeUserId,
    } satisfies FindingAssigneePutReq;
    const url = endpoints.putFindingAssignee.replace('{findingId}', findingId.toString());
    try {
      await queryApi(url, account, 'put', data);
      setNewNotification('assignee updated');
      refetchSidebarInformation().catch(console.error);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  const urlOnExternalPlatform = finding.detector === 'sonarqube' && finding.idOnExternalPlatform !== null ?
    `https://sonarcloud.io/project/issues?open=${finding.idOnExternalPlatform}&id=${finding.scannableResourceName}` :
    null;

  return <>
    {urlOnExternalPlatform !== null &&
      <Typography>
        Details on external platform: <a target="_blank" href={urlOnExternalPlatform}>{urlOnExternalPlatform}</a>
      </Typography>
    }
    <Typography>
      Location: {finding.file}
    </Typography>
    <Typography>
      Match: {finding.match}
    </Typography>
    <Typography>
      Hint: {finding.hint}
    </Typography>
    {fix !== null &&
      <ReactDiffViewer oldValue={fix.oldContent} newValue={fix.newContent} splitView={true} />
    }
    <div style={{display: 'flex'}}>
      {fix !== null &&
        <div style={{margin: 2, width: 320}}>
          <Textarea
            minRows={2}
            placeholder="Provide additional instruction / hint (optional)"
            value={additionalHint}
            onChange={e => setAdditionalHint(e.target.value)}
          />
        </div>
      }
      <div style={{margin: 2}}>
        <Button
          onClick={() => loadFix(finding.id)}
          disabled={!finding.isAssociatedWithGithubRepository || finding.hint.length === 0}
        >
          {fix === null ? 'Request Fix' : 'Request New Fix'}
        </Button>
      </div>
      {fix !== null &&
        <div style={{margin: 2}}>
          <Button
            onClick={() => submitFixAcceptance(finding.id, fix)}
            disabled={fixPr !== null}
          >
            Accept Fix
          </Button>
        </div>
      }
    </div>
    {fixPr !== null && <>PR has been created: <a target="_blank" href={fixPr.prUrl}>{fixPr.prUrl}</a></>}
    {!finding.isAssociatedWithGithubRepository &&
      <Typography fontSize="overline" style={{color: 'red'}}>
        Automatic fixing is not available because this finding did not result from any scan
        of a repository that is associated with a GitHub repository.
      </Typography>
    }
    {finding.hint.length === 0 &&
      <Typography fontSize="overline" style={{color: 'red'}}>
        Automatic fixing is not available because this finding does not have a fixing hint.
      </Typography>
    }

    <Box style={{marginTop: 10, marginBottom: 10}}>
      <Select
        placeholder="Assignee"
        defaultValue={finding.assigneeUserId}
        onChange={(_, value) => putAssignee(finding.id, value)}
      >
        <Option value={null}><Typography fontStyle="italic">not assigned</Typography></Option>
        {(usersMinimal ?? []).map(u =>
          <Option key={u.id} value={u.id}>{u.emailAddress}</Option>
        )}
      </Select>
    </Box>
  </>;
}
