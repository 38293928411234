import {config} from '../config';
import type {ScannableResourceType} from '../shared/types';
import {assertNever} from './misc';

export function formatLocation(filePath: string, lineNumber: number, columnNumber: number) {
  if(filePath === '')
    return null;

  return `${filePath}:${lineNumber}:${columnNumber}`;
}

export function formatArray<T>(arr: T[], elementToString: (arg0: T) => string) {
  if(arr.length === 0)
    return '[]';

  const stringifiedElements = arr.map(elementToString);
  const stringifiedList = stringifiedElements.reduce((prev, curr) => `${prev}, ${curr}`);

  return `[${stringifiedList}]`;
}

export function formatCommitHash(commitHash: string) {
  return limitStringLength(commitHash, config.reducedLengths.commitHashes);
}

export function formatRuleName(ruleName: string) {
  return limitStringLength(ruleName, config.reducedLengths.ruleNames, true);
}

export function formatOrganizationName(organizationName: string) {
  return limitStringLength(organizationName, config.reducedLengths.organizationNames, true);
}

function limitStringLength(str: string, maxLength: number, showElipsis = false) {
  const truncatedString = str.slice(0, maxLength);

  return truncatedString.length === str.length ? str : (showElipsis ? `${truncatedString}...` : truncatedString);
}

export function formatScannableResourceType(scannableResourceType: ScannableResourceType) {
  switch(scannableResourceType) {
    case 'repository':
      return 'Repository';
    case 'cloudResource':
      return 'Cloud Resource';
    default:
      assertNever(scannableResourceType);
  }
}
