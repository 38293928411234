import {Box, Button, Input} from '@mui/joy';
import {useState} from 'react';
import {queryApi} from '../../../data/queries';
import type {MongodbatlasTokenPutReq, WebSessionAuthTokenRes} from '../../../shared/types';
import {endpoints} from '../../../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {getErrorMessage} from '../../../errors';

const mongodbatlasApiKeyPageUrl = 'https://cloud.mongodb.com';

interface Props {
  onCompletion: () => Promise<unknown>;
}

export function MongodbatlasAuthentication({onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [mongodbatlasApiPublicKey, setMongodbatlasApiPublicKey] = useState('');
  const [mongodbatlasApiPrivateKey, setMongodbatlasApiPrivateKey] = useState('');

  async function authenticate() {
    const data = {
      mongodbatlasApiPublicKey,
      mongodbatlasApiPrivateKey,
    } satisfies MongodbatlasTokenPutReq;
    try {
      await queryApi(endpoints.putMongodbatlasToken, account, 'put', data);
      await onCompletion();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Box>
      To import Mongodbatlas projects, please log in to <a href={mongodbatlasApiKeyPageUrl} target="_blank">{mongodbatlasApiKeyPageUrl}</a>, click the gear icon in the top left, click on "Access Manager", create a new API key, and paste it here.
    </Box>
    <Box>
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="mongodbatlas API public key"
        value={mongodbatlasApiPublicKey}
        onChange={e => setMongodbatlasApiPublicKey(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="mongodbatlas API private key"
        value={mongodbatlasApiPrivateKey}
        onChange={e => setMongodbatlasApiPrivateKey(e.target.value)}
      />
      <Button
        onClick={authenticate}
        disabled={mongodbatlasApiPublicKey.length === 0 || mongodbatlasApiPrivateKey.length === 0}
      >
        Authenticate
      </Button>
    </Box>
  </>;
}
