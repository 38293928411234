import {Table} from '../../../components/table/Table';
import {useOrganizationsTree} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {OrganizationsTreeRes, WebSessionAuthTokenRes} from '../../../shared/types';
import {accountAtom} from '../../../data/atoms';
import type {RowType} from '../../../components/table/ActualTable';
import {ViewPage} from '../../../layout/ViewPage';
import type {OrganizationsListItem} from '../../../components/OrganizationSelector';
import {getOrganizationsListFromOrganizationTree} from '../../../components/OrganizationSelector';

export function Projects() {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);

  const {data: organizationsTree} = useOrganizationsTree(account);

  const organizationItems = getOrganizationsListFromOrganizationTree(organizationsTree);

  return <ViewPage title="Projects">
    <Table
      columnTitles={['id', 'Name', 'Organization/Team']}
      rows={(organizationItems ?? [])
        // Only display organizations of type 'project'.
        .filter(organization => organization.organizationType === 'project')
        .map(organization => getProjectRow(organization,
          getParentOrganization(organizationsTree, organization.id)))
      }
    />
  </ViewPage>;
}

function getProjectRow(organization: OrganizationsListItem,
  parentOrganization: OrganizationsTreeRes | undefined): RowType {
  return [
    organization.id,
    organization.name,
    parentOrganization?.name ?? 'unknown',
  ];
}

function getParentOrganization(organizationsTree: OrganizationsTreeRes | undefined,
  organizationId: number): (OrganizationsTreeRes | undefined) {
  if(organizationsTree === undefined)
    return undefined;

  if(organizationsTree.children.findIndex(o => o.id === organizationId) !== -1)
    return organizationsTree;

  for(const child of organizationsTree.children) {
    const candidate = getParentOrganization(child, organizationId);
    if(candidate !== undefined)
      return candidate;
  }

  return undefined;
}
