import {Box, Button} from '@mui/joy';
import {GITHUB_OAUTH_CLIENT_ID, endpoints} from '../../../data/endpoints';
import {queryApi} from '../../../data/queries';
import type {GithubOAuthStateRes, WebSessionAuthTokenRes} from '../../../shared/types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {getErrorMessage} from '../../../errors';

interface Props {
  onCompletion: () => Promise<unknown>;
}

export function GithubAuthentication({onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  async function authenticate() {
    try {
      const githubOAuthStateRes =
        await queryApi<GithubOAuthStateRes>(endpoints.postGithubOAuthState, account, 'post');

      const url = 'https://github.com/login/oauth/authorize?' +
        `client_id=${GITHUB_OAUTH_CLIENT_ID}&scope=read:user&state=${githubOAuthStateRes.state}`;

      const popup = window.open(url, 'Github Authentication', 'height=200,width=150');
      if(popup === null) {
        setNewNotification('unable to launch github authentication');
        return;
      }

      popup.focus();

      const interval = setInterval(() => {
        if(popup.closed) {
          clearInterval(interval);
          onCompletion().catch(e => setNewNotification(getErrorMessage(e)));
        }
      }, 1000);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Box>
      To connect a Github account, please click the button below and log in.
    </Box>
    <Box>
      <Button
        onClick={authenticate}
      >
        Log in to Github
      </Button>
    </Box>
  </>;
}
