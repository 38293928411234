import {Box, Button, Typography} from '@mui/joy';
import {queryApi, useMongodbatlasVisibleScannableResources} from '../../../data/queries';
import type {WebSessionAuthTokenRes, MongodbatlasClusterPostReq, MongodbatlasClusterRes, MongodbatlasVisibleScannableResourceRes} from '../../../shared/types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {endpoints} from '../../../data/endpoints';
import {getErrorMessage} from '../../../errors';
import {useState} from 'react';
import {OrganizationSelector} from '../../OrganizationSelector';
import {MongodbatlasAuthentication} from '../../addIntegrationWizard/views/MongodbatlasAuthentication';

interface Props {
  mongodbatlasClusters: MongodbatlasClusterRes[];
  refetchScannableResources: () => unknown;
}

export function ImportMongodbatlasCluster({mongodbatlasClusters, refetchScannableResources}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);
  const {data: visibleScannableResources, refetch: refetchVisibleScannableResources} =
    useMongodbatlasVisibleScannableResources(account);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const displayAuthentication = visibleScannableResources === null;

  const visibleScannableResourcesToDisplay = (visibleScannableResources ?? [])
    .filter(isVisibleScannableResourceNotMongodbatlasCluster);

  function isVisibleScannableResourceNotMongodbatlasCluster(visibleScannableResource: MongodbatlasVisibleScannableResourceRes) {
    return mongodbatlasClusters.find(mongodbatlasCluster =>
      mongodbatlasCluster.clusterId == visibleScannableResource.clusterId) === undefined;
  }

  async function addMongodbatlasCluster(visibleScannableResource: MongodbatlasVisibleScannableResourceRes) {
    const data = {
      groupId: visibleScannableResource.groupId,
      clusterId: visibleScannableResource.clusterId,
      owner: visibleScannableResource.owner,
      name: visibleScannableResource.name,
      organizationId: idSelectedOrganization,
      mongodbatlasIntegrationId: visibleScannableResource.mongodbatlasIntegrationId,
    } satisfies MongodbatlasClusterPostReq;

    try {
      await queryApi(endpoints.postMongodbatlasCluster, account, 'post', data);
      setNewNotification('Repository has been imported.');
      await refetchScannableResources();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  if(displayAuthentication) {
    return <MongodbatlasAuthentication onCompletion={refetchVisibleScannableResources} />;
  }

  return <>
    <Box>
      {visibleScannableResourcesToDisplay.length === 0 ?
        <Typography>
          There are no visible repositories.
        </Typography> :
        <>
          {visibleScannableResourcesToDisplay.map((visibleScannableResource, i) =>
            <Box key={i}>
              <Button sx={{mb: 1}} onClick={() => addMongodbatlasCluster(visibleScannableResource)}>
                add {visibleScannableResource.owner}/{visibleScannableResource.name}
              </Button>
            </Box>
          )}

          <OrganizationSelector
            limitToGloballySelectedOrganizationItem={true}
            onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
            organizationTypeFilter={['project']}
          />
        </>
      }
    </Box>
    <Box>
      To make (more) mongodbatlas projects visible, add API keys to more
      Mongodb Atlas organizations on the integration page.
    </Box>
  </>;
}
