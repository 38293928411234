import {Box, Button, Typography} from '@mui/joy';
import {queryApi, useSonarcloudVisibleScannableResources} from '../../../data/queries';
import type {WebSessionAuthTokenRes, SonarcloudProjectPostReq, SonarcloudProjectRes, SonarcloudVisibleRepositoryRes} from '../../../shared/types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {endpoints} from '../../../data/endpoints';
import {getErrorMessage} from '../../../errors';
import {useState} from 'react';
import {OrganizationSelector} from '../../OrganizationSelector';
import {SonarcloudAuthentication} from '../../addIntegrationWizard/views/SonarcloudAuthentication';

interface Props {
  sonarcloudProjects: SonarcloudProjectRes[];
  refetchScannableResources: () => unknown;
}

export function ImportSonarcloudProject({sonarcloudProjects, refetchScannableResources}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);
  const {data: visibleRepositories, refetch: refetchVisibleRepositories} =
    useSonarcloudVisibleScannableResources(account);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const displayAuthentication = visibleRepositories === null;

  const visibleRepositoriesToDisplay = (visibleRepositories ?? [])
    .filter(isVisibleRepositoryNotSonarcloudProject);

  function isVisibleRepositoryNotSonarcloudProject(visibleRepository: SonarcloudVisibleRepositoryRes) {
    return sonarcloudProjects
      .find(sonarcloudProject => sonarcloudProject.owner == visibleRepository.owner &&
        sonarcloudProject.name == visibleRepository.name) === undefined;
  }

  async function addSonarcloudProject(visibleRepository: SonarcloudVisibleRepositoryRes) {
    const data = {
      key: visibleRepository.key,
      owner: visibleRepository.owner,
      name: visibleRepository.name,
      organizationId: idSelectedOrganization,
      sonarcloudIntegrationId: visibleRepository.sonarcloudIntegrationId,
    } satisfies SonarcloudProjectPostReq;

    try {
      await queryApi(endpoints.postSonarcloudProject, account, 'post', data);
      setNewNotification('Repository has been imported.');
      await refetchScannableResources();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  if(displayAuthentication) {
    return <SonarcloudAuthentication onCompletion={refetchVisibleRepositories} />;
  }

  return <>
    <Box>
      {visibleRepositoriesToDisplay.length === 0 ?
        <Typography>
          There are no visible repositories.
        </Typography> :
        <>
          {visibleRepositoriesToDisplay.map((visibleRepository, i) =>
            <Box key={i}>
              <Button sx={{mb: 1}} onClick={() => addSonarcloudProject(visibleRepository)}>
                add {visibleRepository.owner}/{visibleRepository.name}
              </Button>
            </Box>
          )}

          <OrganizationSelector
            limitToGloballySelectedOrganizationItem={true}
            onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
            organizationTypeFilter={['project']}
          />
        </>
      }
    </Box>
    <Box>
      To make (more) sonarcloud projects visible, add more integrations on the integrations page.
    </Box>
  </>;
}
