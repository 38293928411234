import {NotFound} from './views/notFound/NotFound';
import {Routes, Route} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {Dashboard} from './views/loggedIn/overview/Dashboard';
import {Devices} from './views/loggedIn/administration/Devices';
import {Scans} from './views/loggedIn/notInSidebar/Scans';
import {Findings} from './views/loggedIn/findings/Findings';
import {Tasks} from './views/loggedIn/tasks/Tasks';
import {Users} from './views/loggedIn/administration/Users';
import {Resources} from './views/loggedIn/administration/Resources';
import {config} from './config';
import {Notifications} from './Notifications';
import {ProtectedRoute} from './ProtectedRoute';
import {Verification} from './views/loggedOut/auth/Verification';
import {Rules} from './views/loggedIn/administration/Rules';
import {Organizations} from './views/loggedIn/administration/Organizations';
import {Policies} from './views/loggedIn/administration/Policies';
import {RuleViolations} from './views/loggedIn/ruleViolations/RuleViolations';
import {Organigram} from './views/loggedIn/administration/Organigram';
import {Projects} from './views/loggedIn/administration/Projects';
import {PolicyViolations} from './views/loggedIn/compliance/PolicyViolations';
import {Integrations} from './views/loggedIn/administration/Integrations';

export function App() {
  const queryClient = new QueryClient();

  return <>
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={config.urls.pages.root}>
          <Route index element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.verification}>
          <Route index element={<Verification />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.rules}>
          <Route index element={<ProtectedRoute><Rules /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.ruleViolations}>
          <Route index element={<ProtectedRoute><RuleViolations /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.policies}>
          <Route index element={<ProtectedRoute><Policies /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.policyViolations}>
          <Route index element={<ProtectedRoute><PolicyViolations /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.devices}>
          <Route index element={<ProtectedRoute><Devices /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.scans}>
          <Route index element={<ProtectedRoute><Scans /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.findings}>
          <Route index element={<ProtectedRoute><Findings /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.tasks}>
          <Route index element={<ProtectedRoute><Tasks /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.users}>
          <Route index element={<ProtectedRoute><Users /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.organizations}>
          <Route index element={<ProtectedRoute><Organizations /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.organigram}>
          <Route index element={<ProtectedRoute><Organigram /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.projects}>
          <Route index element={<ProtectedRoute><Projects /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.resources}>
          <Route index element={<ProtectedRoute><Resources /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={config.urls.pages.administration.integrations}>
          <Route index element={<ProtectedRoute><Integrations /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    <Notifications />
  </>;
}
