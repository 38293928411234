import {useRecoilValue} from 'recoil';
import {useUsersMinimal} from '../data/queries';
import type {WebSessionAuthTokenRes} from '../shared/types';
import {accountAtom} from '../data/atoms';
import {Fragment} from 'react/jsx-runtime';
import {Chip} from '@mui/joy';

interface Props {
  organizationId: number;
}

export function UsersChips({organizationId}: Props) {
  console.log('organizationId', organizationId);
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const {data: usersMinimal} = useUsersMinimal(account, [organizationId]);

  return (usersMinimal ?? []).map((u, i) => <Fragment key={i}>
    <Chip>
      {u.emailAddress}
    </Chip>
  </Fragment>);
}
