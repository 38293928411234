import {Box, Button, Typography} from '@mui/joy';
import {SonarcloudAuthentication} from './views/SonarcloudAuthentication';
import {SonatypeAuthentication} from './views/SonatypeAuthentication';
import {AzureAuthentication} from './views/AzureAuthentication';
import {MongodbatlasAuthentication} from './views/MongodbatlasAuthentication';
import {GithubAuthentication} from './views/GithubAuthentication';

interface Props {
  setTitle: (newTitle: string) => unknown;
  setActiveComponent: (wizardComponent: JSX.Element) => unknown;
  wizardComponentProps: WizardComponentProps;
}

interface WizardComponentProps {
  onCompletion: () => Promise<unknown>;
}

interface ResourceImportConfig {
  name: string;
  component: JSX.Element;
}

interface ResourceImportConfigGroup {
  name: string;
  resourceImportConfigs: ResourceImportConfig[];
}

export function IntegrationTypeSelector({setTitle, setActiveComponent, wizardComponentProps}: Props) {
  const resourceImportConfigGroups: ResourceImportConfigGroup[] = [
    {
      name: 'Repository / Software',
      resourceImportConfigs: [
        {
          name: 'Github',
          component: <GithubAuthentication
            onCompletion={wizardComponentProps.onCompletion}
          />
        },
        {
          name: 'Sonarcloud',
          component: <SonarcloudAuthentication
            onCompletion={wizardComponentProps.onCompletion}
          />,
        },
        {
          name: 'Sonatype',
          component: <SonatypeAuthentication
            onCompletion={wizardComponentProps.onCompletion}
          />,
        },
      ],
    },
    {
      name: 'Cloud Resources',
      resourceImportConfigs: [
        {
          name: 'Azure',
          component: <AzureAuthentication
            onCompletion={wizardComponentProps.onCompletion}
          />,
        },
        {
          name: 'Mongodbatlas',
          component: <MongodbatlasAuthentication
            onCompletion={wizardComponentProps.onCompletion}
          />,
        },
      ],
    },
  ];

  return <>
    <Typography sx={{marginBottom: 2}}>
      Select the type of integration you want do add.
    </Typography>

    <Box>
      {resourceImportConfigGroups.map((resourceImportConfigGroup, iGroup) =>
        <Box key={iGroup} sx={{marginBottom: 1.5}}>
          <Typography level="h4" sx={{marginBottom: 0.2}}>
            {resourceImportConfigGroup.name}
          </Typography>

          {resourceImportConfigGroup.resourceImportConfigs.map((resourceImportConfig, i) =>
            <Box key={i} sx={{marginBottom: 1}}>
              <Button
                onClick={() => {
                  setTitle(`Import ${resourceImportConfig.name}`);
                  setActiveComponent(resourceImportConfig.component);
                }}
              >
                {resourceImportConfig.name}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  </>;
}
