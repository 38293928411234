export function msAzurePipeline() {
  return `variables:
- group: "default_environment"
- name: SECGURO_CI_TOKEN
  value: $[variables.SECGURO_CI_TOKEN]

trigger:
- none

pr:
  drafts: false
  branches:
    include:
    - "*"

resources:
- repo: self

stages:
- stage: Build
  displayName: Build and push stage
  jobs:  
  - job: Build
    displayName: Build
    pool:
      vmImage: 'ubuntu-22.04'
    steps:
    - task: CmdLine@2
      displayName: Check for Secguro Violations
      inputs:
        script: wget 'https://secguro.github.io/secguro-cli/secguro' && chmod +x secguro && SECGURO_CI_TOKEN="$(SECGURO_CI_TOKEN)" ./secguro scan --tolerance 0`;
}
