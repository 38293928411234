export const config = {
  urls: {
    pages: {
      root: '/',
      verification: '/auth/verification',
      ruleViolations: '/ruleViolations',
      policyViolations: '/compliance',
      scans: '/scans',
      findings: '/findings',
      tasks: '/tasks',
      administration: {
        users: '/administration/users',
        organizations: '/administration/organizations',
        organigram: '/administration/organigram',
        projects: '/administration/projects',
        devices: '/administration/devices',
        resources: '/administration/resources',
        integrations: '/administration/integrations',
        policies: '/administration/policies',
        rules: '/administration/rules',
      },
    },
  },
  notifications: {
    timeoutInMs: 5000,
  },
  colors: {
    severity: {
      error: 'red',
      warning: 'orange',
      info: 'lightblue',
    },
    highlighting: {
      tableRow: 'rgb(255,215,0,0.7)',
    },
    chips: {
      scannableResource: 'lightgreen',
      organization: 'gray',
    },
  },
  remoteUrls: {
    minimumLength: 12,
    githubRemoteUrlRegex: /^(?:https:\/\/github\.com\/|git@github\.com:)([^/]+)\/([^/]+)\.git$/,
    msAzureUrlRegex: /(?:https:\/\/[\w.@]+\/|git@[\w.]+:v\d\/)([\w-]+)\/([\w-]+)\b/,
  },
  reducedLengths: {
    commitHashes: 6,
    ruleNames: 16,
    organizationNames: 16,
  },
};
