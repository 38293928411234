import {Box, Button, Checkbox, Input, Typography} from '@mui/joy';
import {useState} from 'react';
import type {WebSessionAuthTokenRes, UserPostReq} from '../shared/types';
import {endpoints} from '../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../data/atoms';
import {getErrorMessage} from '../errors';
import {queryApi} from '../data/queries';
import {OrganizationSelector} from './OrganizationSelector';

interface Props {
  refetchUsers: () => unknown;
}

export function AddUser({refetchUsers}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const [emailAddress, setEmailAddress] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  async function addUser() {
    const data = {
      emailAddress,
      isAdmin,
      organizationId: idSelectedOrganization,
    } satisfies UserPostReq;

    try {
      await queryApi(endpoints.postUsers, account, 'post', data);
      setNewNotification('User has been created.');
      await refetchUsers();

      setEmailAddress('');
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Typography sx={{marginBottom: 2}}>
      To add a user to this organization, enter their email address. We will
      send them an email asking them to set their password.
    </Typography>
    <Input
      sx={{marginBottom: 2}}
      placeholder="email address"
      value={emailAddress}
      onChange={e => setEmailAddress(e.target.value)}
    />
    <Box sx={{marginBottom: 2}}>
      <Checkbox
        checked={isAdmin}
        onChange={() => setIsAdmin(!isAdmin)}
      />
      {' admin'}
    </Box>
    <OrganizationSelector
      limitToGloballySelectedOrganizationItem={true}
      onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
      organizationTypeFilter={['organization', 'team']}
    />
    <Button
      disabled={emailAddress === ''}
      onClick={addUser}
    >
      Submit
    </Button>
  </>;
}

