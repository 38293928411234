import type {UseQueryOptions} from 'react-query';
import {useQuery} from 'react-query';
import {DEFAULT_HEADER, endpoints} from './endpoints';
import type {AxiosResponse} from 'axios';
import axios from 'axios';
import qs from 'qs';
import {API_V1} from '../shared/types';
import type {AxiosHeaders, AxiosRequestConfig, Method, RawAxiosRequestHeaders} from 'axios';
import type {
  WebSessionAuthTokenRes,
  FindingRes,
  ScannableResourceRes,
  ScanRes,
  GithubVisibleRepositoryRes,
  DeviceRes,
  UserRes,
  SidebarInformationRes,
  OngoingScanRes,
  Page,
  RuleRes,
  PolicyRes,
  ScannableResourceResMinimal,
  OrganizationsTreeRes,
  PolicyViolationRes,
  UserResMinimal,
  SonarcloudVisibleRepositoryRes,
  AzureVisibleScannableResourceRes,
  SonatypeVisibleRepositoryRes,
  MongodbatlasVisibleScannableResourceRes,
  IntegrationRes,
  PredefinedPolicyRes,
} from '../shared/types';

type MethodsHeaders = Partial<{
  [Key in Method as Lowercase<Key>]: AxiosHeaders;
} & {common: AxiosHeaders}>;

const queryOptions = {
  refetchInterval: 10e3,
} satisfies UseQueryOptions;

function paramsSerializer(params: Record<string, unknown>) {
  return qs.stringify(params, {arrayFormat: 'repeat'});
}

export async function queryApi<T>(endpoint: string,
  authToken: WebSessionAuthTokenRes | null | undefined = undefined,
  method: 'get' | 'post' | 'delete' | 'put' = 'get',
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // Anything more specific would not yield any typing advantage because axios.request() never uses the generic type of data.
  data: Record<string, any> | undefined = undefined): Promise<T> {
  const headers: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders = {
    'accept': API_V1,
    'content-type': 'application/json',
  };
  if(authToken !== null && authToken !== undefined) {
    headers['Authorization'] = authToken.provider + ' ' + authToken.authToken;
  }

  const requestConfig: AxiosRequestConfig = {method, headers, url: endpoint, paramsSerializer};
  if(data !== undefined) {
    if(method === 'post' || method === 'put' || method === 'delete')
      requestConfig.data = data;
    else
      requestConfig.params = data;
  }
  const response = await axios.request<T>(requestConfig);
  return response.data;
}

function useX<T>(authToken: WebSessionAuthTokenRes | null | undefined, endpoint: string,
  params?: Record<string, unknown>, queryKeyExtension?: Parameters<typeof useQuery>[0]) {
  return useQuery(
    [endpoint, ...(queryKeyExtension ?? [])],
    async () => {
      const response: AxiosResponse<T> = await axios.get(
        endpoint, {
          headers: authToken === null || authToken === undefined ?
            DEFAULT_HEADER :
            {
              ...DEFAULT_HEADER,
              'Authorization': authToken.provider + ' ' + authToken.authToken,
            },
          params,
          paramsSerializer,
        });
      return response.data;
    },
    queryOptions,
  );
}

export const useAccounts = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<WebSessionAuthTokenRes[]>(authToken, endpoints.getAccounts);

export const useSidebarInformation = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<SidebarInformationRes>(authToken, endpoints.getSidebarInformation);

export const useOrganizationsTree = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<OrganizationsTreeRes>(authToken, endpoints.getOrganizationsTree);

export const useUser = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<UserRes>(authToken, endpoints.getUser);

export const useUsers = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[],
  pageNumber: number, searchString: string) =>
  useX<Page<UserRes>>(authToken, endpoints.getUsers, {organizationIds, pageNumber, searchString});

export const useUsersMinimal = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[]) =>
  useX<UserResMinimal[]>(authToken, endpoints.getUsersMinimal, {organizationIds}, organizationIds);

export const useDevices = (authToken: WebSessionAuthTokenRes | null | undefined, pageNumber: number, searchString: string) =>
  useX<Page<DeviceRes>>(authToken, endpoints.getDevices, {pageNumber, searchString});

export const useOngoingScans = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[]) =>
  useX<OngoingScanRes[]>(authToken, endpoints.getOngoingScans, {organizationIds});

export const useScans = (authToken: WebSessionAuthTokenRes | null | undefined, scannableResourceId: number,
  pageNumber: number, searchString: string) =>
  useX<Page<ScanRes>>(authToken, endpoints.getScans.replace('{scannableResourceId}', scannableResourceId.toString()),
    {pageNumber, searchString});

export const useRules = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[],
  pageNumber: number) =>
  useX<Page<RuleRes>>(authToken, endpoints.getRules, {organizationIds, pageNumber});

export const useRuleViolations = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[],
  pageNumber: number) =>
  useX<Page<RuleRes>>(authToken, endpoints.getRuleViolations, {organizationIds, pageNumber});

export const usePredefinedPolicies = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<PredefinedPolicyRes[]>(authToken, endpoints.getPredefinedPolicies);

export const usePolicies = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[],
  pageNumber: number) =>
  useX<Page<PolicyRes>>(authToken, endpoints.getPolicies, {organizationIds, pageNumber});

export const usePolicyViolations = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[],
  pageNumber: number) =>
  useX<Page<PolicyViolationRes>>(authToken, endpoints.getPolicyViolations, {organizationIds, pageNumber});

export const useFindings = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[], pageNumber: number,
  searchString: string, scannableResourceNameFilter: string, severityFilter: string,
  scanId: string | null) =>
  useX<Page<FindingRes>>(authToken, endpoints.getFindings, {
    organizationIds,
    pageNumber,
    searchString,
    scannableResourceNameFilter,
    severityFilter,
    scanId,
  });

export const useVisibleRepositories = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<GithubVisibleRepositoryRes[]>(authToken, endpoints.getVisibleRepositories);

export const useSonarcloudVisibleScannableResources = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<(SonarcloudVisibleRepositoryRes[]) | null>(authToken, endpoints.getSonarcloudVisibleScannableResources);

export const useSonatypeVisibleScannableResources = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<(SonatypeVisibleRepositoryRes[]) | null>(authToken, endpoints.getSonatypeVisibleScannableResources);

export const useAzureVisibleScannableResources = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<(AzureVisibleScannableResourceRes[]) | null>(authToken, endpoints.getAzureVisibleScannableResources);

export const useMongodbatlasVisibleScannableResources = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<(MongodbatlasVisibleScannableResourceRes[]) | null>(authToken,
    endpoints.getMongodbatlasVisibleScannableResources);

export const useScannableResources = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[],
  pageNumber: number, searchString: string) =>
  useX<Page<ScannableResourceRes>>(authToken, endpoints.getScannableResources,
    {organizationIds, pageNumber, searchString});

export const useScannableResourcesMinimal = (authToken: WebSessionAuthTokenRes | null | undefined) =>
  useX<ScannableResourceResMinimal[]>(authToken, endpoints.getScannableResourcesMinimal);

export const useIntegrations = (authToken: WebSessionAuthTokenRes | null | undefined, organizationIds: number[]) =>
  useX<IntegrationRes[]>(authToken, endpoints.getIntegrations, {organizationIds});
