import type {ReactNode} from 'react';
import Modal from '@mui/joy/Modal';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px',
  pt: 2,
  px: 4,
  pb: 3,
};

interface Props {
  title: string;
  open: boolean;
  handleClose: () => void;
  wide?: boolean;
  children: ReactNode;
}

export function CustomModal({title, open, handleClose, wide = false, children}: Props) {
  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{...style, width: wide ? '70%' : 500, maxWidth: 'calc(100% - 32px - 32px)', maxHeight: '85%', overflow: 'scroll'}}>
        <h2 id="child-modal-title">{title}</h2>
        {children}
        <div style={{display: 'flex'}}>
          <Button onClick={handleClose} style={{marginLeft: 'auto'}}>close</Button>
        </div>
      </Box>
    </Modal>
  );
}
