import Box from '@mui/joy/Box';
import {useSetRecoilState} from 'recoil';
import type {UserVerificationPostReq} from '../../../shared/types';
import {newNotificationAtom} from '../../../data/atoms';
import {Button, Input} from '@mui/joy';
import {endpoints} from '../../../data/endpoints';
import {ShouldNeverHappenError} from '../../../utils/misc';
import {useState} from 'react';
import {getErrorMessage} from '../../../errors';
import {useNavigate} from 'react-router-dom';
import {config} from '../../../config';
import {ViewPage} from '../../../layout/ViewPage';
import {queryApi} from '../../../data/queries';

export function Verification() {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const verificationCode = urlParams.get('verificationCode');
  if(verificationCode === null)
    throw new Error('verification code not found');

  const setPassword = !verificationCode.startsWith('initial-signup-');

  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [password0, setPassword0] = useState('');
  const [password1, setPassword1] = useState('');

  async function verify() {
    if(verificationCode === null)
      throw new ShouldNeverHappenError();

    const data = {
      verificationCode,
      password: setPassword ? password0 : undefined,
    } satisfies UserVerificationPostReq;
    try {
      await queryApi(endpoints.postUserVerification, undefined, 'post', data);
      setNewNotification('Verification successful.');
      navigate(config.urls.pages.root);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <ViewPage title="Verification">
    <Box>
      {setPassword &&
        <>
          <Input
            style={{width: 200, marginBottom: 2}}
            placeholder="password"
            value={password0}
            onChange={e => setPassword0(e.target.value)}
            type="password"
          />
          <Input
            style={{width: 200, marginBottom: 2}}
            placeholder="password (confirmation)"
            value={password1}
            onChange={e => setPassword1(e.target.value)}
            type="password"
          />
        </>
      }
      <Button
        onClick={verify}
        disabled={setPassword && (password0.length === 0 || password0 !== password1)}
      >
        {setPassword ? 'Set Password' : 'Verify Email Address'}
      </Button>
    </Box>
  </ViewPage>;
}
