import Box from '@mui/joy/Box';
import {useSetRecoilState} from 'recoil';
import type {WebSessionAuthTokenRes, WebSessionAuthTokenPostReq} from '../../../shared/types';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {Button, Input} from '@mui/joy';
import {endpoints} from '../../../data/endpoints';
import {useState} from 'react';
import {getErrorMessage} from '../../../errors';
import {ViewPage} from '../../../layout/ViewPage';
import {queryApi} from '../../../data/queries';

export function Login() {
  const setAccount = useSetRecoilState<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  async function login() {
    const data = {
      emailAddress,
      password,
    } satisfies WebSessionAuthTokenPostReq;
    try {
      const newWebSessionAuthToken =
        await queryApi<WebSessionAuthTokenRes>(endpoints.postWebSessionAuthToken, undefined, 'post', data);
      setAccount(newWebSessionAuthToken);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <ViewPage title="Login">
    <Box>
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="email address"
        value={emailAddress}
        onChange={e => setEmailAddress(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        type="password"
      />
      <Button
        onClick={login}
        disabled={emailAddress.length === 0 || password.length === 0}
      >
        Login
      </Button>
    </Box>
  </ViewPage>;
}
