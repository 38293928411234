import {FormControl, Select, Option, Box} from '@mui/joy';
import type {OrganizationType, OrganizationsTreeRes} from '../shared/types';
import {useOrganizationsTree} from '../data/queries';
import {useRecoilValue} from 'recoil';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../data/atoms';
import {useEffect, useState} from 'react';
import {getOrganizationTypeIcon} from '../views/loggedIn/administration/Organigram';

export interface OrganizationsListItem {
  id: number;
  name: string;
  organizationType: OrganizationType;
  transitiveChildren: OrganizationsListItem[];
}

interface Props {
  limitToGloballySelectedOrganizationItem: boolean;
  organizationTypeFilter?: OrganizationType[];
  onSelect?: (selectedOrganizationItem: OrganizationsListItem) => unknown;
}

export function OrganizationSelector({limitToGloballySelectedOrganizationItem,
  organizationTypeFilter, onSelect}: Props) {
  const account = useRecoilValue(accountAtom);
  const {data: organizationsTree} = useOrganizationsTree(account);
  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const [organizationItems, setOrganizationItems] = useState<OrganizationsListItem[]>([]);

  useEffect(() => {
    const availableOrganizationItems = limitToGloballySelectedOrganizationItem ?
      (globallySelectedOrganizationItem === null ? [] :
        [globallySelectedOrganizationItem, ...globallySelectedOrganizationItem.transitiveChildren]) :
      getOrganizationsListFromOrganizationTree(organizationsTree);

    const filteredOrganizationItems = organizationTypeFilter === undefined ?
      availableOrganizationItems :
      availableOrganizationItems.filter(oi => organizationTypeFilter.includes(oi.organizationType));

    setOrganizationItems(filteredOrganizationItems);
  }, [limitToGloballySelectedOrganizationItem, globallySelectedOrganizationItem, organizationsTree]);

  /*
   The type of the `onChange` property of `Select` is incorrect. The 2nd
   parameter may be of arbitrary type because property `value` of `Option`
   is of type any. In the case of this component, it's always a number
   (the organization ID).

   Do not attempt to replace this logic with logic that uses the `onSelect`
   property of `Option`. Using this property would be much more handy.
   However, this `onSelect` function seems to never get called.
  */
  function handleSelectionChange(_: unknown, organizationId: string | number | null) {
    // Happens on load.
    if(organizationId === null)
      return;

    if(typeof organizationId !== 'number')
      throw new Error('did not receive an organization ID: ' + organizationId);

    setIdSelectedOrganization(organizationId);

    if(onSelect === undefined)
      return;

    const organizationItem = organizationItems.find(oi => oi.id === organizationId);
    if(organizationItem === undefined)
      throw new Error('organization item not found: ' + organizationId);

    onSelect(organizationItem);
  }

  // Set initially selected organization (root organization).
  useEffect(() => {
    if(organizationItems.length === 0 || idSelectedOrganization !== -1)
      return;

    handleSelectionChange(undefined, organizationItems[0].id);
  }, [organizationItems]);

  return organizationItems.length === 1 && globallySelectedOrganizationItem?.id === organizationItems[0].id ?
    <></> :
    <FormControl size="sm">
      <Select
        sx={{marginBottom: 2}}
        size="sm"
        placeholder="Select project"
        value={idSelectedOrganization}
        onChange={handleSelectionChange}
        renderValue={(value) => {
          const organizationItem = organizationItems.find(oi => oi.id === value?.value);
          if(organizationItem === undefined)
            return <>failed to load</>;

          const OrganizationTypeIcon = getOrganizationTypeIcon(organizationItem.organizationType);

          return <>
            <Box>
              <OrganizationTypeIcon /> {organizationItem.name}
            </Box>
          </>;
        }}
      >
        {organizationItems.map(organizationItem => {
          const OrganizationTypeIcon = getOrganizationTypeIcon(organizationItem.organizationType);

          return (
            <Option
              key={organizationItem.id}
              value={organizationItem.id}
            >
              <>
                <Box>
                  {<OrganizationTypeIcon />} {organizationItem.name}
                </Box>
              </>
            </Option>
          );
        }
        )}
      </Select>
    </FormControl>;
}

export function getOrganizationsListFromOrganizationTree(
  organizationsTreeElement: OrganizationsTreeRes | undefined): OrganizationsListItem[] {
  if(organizationsTreeElement === undefined)
    return [];

  const transitiveChildren = organizationsTreeElement.children
    .map(getOrganizationsListFromOrganizationTree).flat();

  const currentItem: OrganizationsListItem = {
    id: organizationsTreeElement.id,
    name: organizationsTreeElement.name,
    organizationType: organizationsTreeElement.organizationType,
    transitiveChildren,
  };

  return [currentItem, ...transitiveChildren];
}
