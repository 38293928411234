import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import {IoMdDownload} from 'react-icons/io';
import {Table} from '../../../components/table/Table';
import type {WebSessionAuthTokenRes, GithubRepositoryPutReq, GithubRepositoryRes, ScannableResourceRes} from '../../../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom, newNotificationAtom} from '../../../data/atoms';
import {queryApi, useScannableResources} from '../../../data/queries';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useEffect, useState} from 'react';
import {CustomModal} from '../../../components/CustomModal';
import {endpoints} from '../../../data/endpoints';
import {Box, Checkbox, IconButton, Input, Stack} from '@mui/joy';
import {hoursToMs, msToHours} from '../../../utils/units';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import {getErrorMessage} from '../../../errors';
import {getArrayValueSetter} from '../../../utils/react';
import {CiIntegrationInformation} from '../../../components/CiIntegrationInformation';
import {getRepositoryType} from '../../../utils/repositoryRemoteUrls';
import {OngoingScansHint} from '../../../components/OngoingScansHint';
import type {RowType} from '../../../components/table/ActualTable';
import {ViewPage} from '../../../layout/ViewPage';
import {getOrganizationIdsFromOrganizationItem} from '../../../utils/organizationFiltering';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import {GrConfigure} from 'react-icons/gr';
import {useNavigate, type NavigateFunction} from 'react-router-dom';
import {config} from '../../../config';
import {BooleanIndicator} from '../../../components/BooleanIndicator';
import {formatArray, formatScannableResourceType} from '../../../utils/textFormatting';
import {ImportResourceWizard} from '../../../components/importResourceWizard/ImportResourceWizard';
import {Coverage} from '../../../components/Coverage';

export function Resources() {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState('');

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const {data: scannableResourcesPage, refetch: refetchScannableResources} =
    useScannableResources(account,
      getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem), pageNumber, searchString);

  const [scannableResources, setScannableResources] = useState(scannableResourcesPage?.items ?? []);
  useEffect(() => {
    setScannableResources(scannableResourcesPage?.items ?? []);
  }, [scannableResourcesPage]);

  const [importResourceWizardOpen, setImportResourceWizardOpen] = useState(false);
  const [selectedScannableResourceForCiIntegration, setSelectedScannableResourceForCiIntegration] =
    useState<ScannableResourceRes | null>(null);

  useEffect(() => {
    refetchScannableResources().catch(console.error);
  }, [globallySelectedOrganizationItem, pageNumber, searchString]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchString]);

  async function putRow(githubRepository: GithubRepositoryRes) {
    const url = endpoints.putGithubRepository.replace('{githubRepositoryId}', githubRepository.id.toString());
    const data = {
      periodicScanFrequencyInMs: githubRepository.periodicScanFrequencyInMs,
      periodicScanBranchName: githubRepository.periodicScanBranchName,
      scanOnPushEnabled: githubRepository.scanOnPushEnabled,
      scanOnPushBranchNameRegex: githubRepository.scanOnPushBranchNameRegex,
    } satisfies GithubRepositoryPutReq;
    try {
      await queryApi(url, account, 'put', data);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  const topElements = [
    <Button
      style={{marginLeft: 10}}
      color="primary"
      startDecorator={<IoMdDownload />}
      size="sm"
      onClick={() => setImportResourceWizardOpen(true)}
    >
      New
    </Button>,
  ];

  return <ViewPage title="Resources" topElements={topElements}>
    <OngoingScansHint />

    <Table
      columnTitles={['id', 'Name', 'Project', 'Type', 'Hoster', 'Coverage', 'Activity',
        'Findings', 'Settings']}
      rows={(scannableResources ?? []).toSorted(comparator)
        .map((scannableResource, i) => getScannableResourceRow(navigate,
          getArrayValueSetter(scannableResources, setScannableResources, i),
          setSelectedScannableResourceForCiIntegration, putRow, scannableResource))}
      onSearchStringChange={setSearchString}
      pagination={
        {
          pageNumber,
          pageSize: scannableResourcesPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: scannableResourcesPage?.totalNumberOfItems ?? 0,
        }
      }
    />

    {importResourceWizardOpen &&
      <ImportResourceWizard
        closeWizard={() => setImportResourceWizardOpen(false)}
        scannableResources={scannableResources}
        refetchScannableResources={refetchScannableResources}
      />
    }

    {selectedScannableResourceForCiIntegration !== null &&
      <CustomModal
        open={true}
        handleClose={() => {setSelectedScannableResourceForCiIntegration(null);}}
        title={`CI Integration for ${selectedScannableResourceForCiIntegration.name}`}
        wide={true}
      >
        <CiIntegrationInformation
          remoteUrl={selectedScannableResourceForCiIntegration.remoteUrl}
          githubRepositoryId={selectedScannableResourceForCiIntegration.githubRepository?.id}
          ciToken={selectedScannableResourceForCiIntegration.ciToken}
        />
      </CustomModal >
    }
  </ViewPage>;
}

function comparator(a: ScannableResourceRes, b: ScannableResourceRes) {
  return a.id - b.id;
}

function getScannableResourceRow(navigate: NavigateFunction,
  setScannableResource: (arg0: ScannableResourceRes) => unknown,
  setSelectedScannableResourceForCiIntegration: (arg0: ScannableResourceRes) => unknown,
  putRow: (githubRepository: GithubRepositoryRes) => Promise<unknown>,
  scannableResource: ScannableResourceRes): RowType {
  const githubRepository = scannableResource.githubRepository;

  const platformInfo = formatArray(getPlatformInfo(scannableResource), x => x);

  const writabilityIcon = <BooleanIndicator value={scannableResource.githubRepository !== null} />;

  const scanOnPushToBranch = githubRepository === null ? 'not available' :
    <div style={{display: 'flex', alignItems: 'center'}}>
      <Checkbox
        label="enabled"
        checked={githubRepository.scanOnPushEnabled}
        onChange={e => {
          const newGithubRepository = {
            ...githubRepository,
            scanOnPushEnabled: e.target.checked,
          } satisfies GithubRepositoryRes;
          putRow(newGithubRepository).catch(console.error);
          setScannableResource({...scannableResource, githubRepository: newGithubRepository});
        }}
      />
      <Input
        sx={{ml: 2}}
        placeholder="branch name regex"
        value={githubRepository.scanOnPushBranchNameRegex}
        onChange={e => {
          const newGithubRepository = {
            ...githubRepository,
            scanOnPushBranchNameRegex: e.target.value,
          } satisfies GithubRepositoryRes;
          putRow(newGithubRepository).catch(console.error);
          setScannableResource({...scannableResource, githubRepository: newGithubRepository});
        }}
      />
    </div>;

  const periodicScan = githubRepository === null ? 'not available' :
    <div style={{display: 'flex', alignItems: 'center'}}>
      <Input
        sx={{width: 100}}
        placeholder=""
        value={msToHours(githubRepository.periodicScanFrequencyInMs)}
        onChange={e => {
          const valueAsInt = parseInt(e.target.value);
          if(valueAsInt >= 0) {
            const newGithubRepository = {
              ...githubRepository,
              periodicScanFrequencyInMs: hoursToMs(valueAsInt),
            } satisfies GithubRepositoryRes;
            putRow(newGithubRepository).catch(console.error);
            setScannableResource({...scannableResource, githubRepository: newGithubRepository});
          }
        }}
      />
      hours
      <Input
        sx={{ml: 2}}
        placeholder="branch name"
        value={githubRepository.periodicScanBranchName}
        onChange={e => {
          const newGithubRepository = {
            ...githubRepository,
            periodicScanBranchName: e.target.value,
          } satisfies GithubRepositoryRes;
          putRow(newGithubRepository).catch(console.error);
          setScannableResource({...scannableResource, githubRepository: newGithubRepository});
        }}
      />
    </div>
    ;

  const ciIntegration =
    <Typography level="body-xs">
      <IconButton onClick={() => setSelectedScannableResourceForCiIntegration(scannableResource)}>
        <ReadMoreIcon />
      </IconButton>
    </Typography>;

  const coverage =
    <Coverage
      scannableResource={scannableResource}
      coverageTypesToDisplay={scannableResource.scannableResourceType === 'repository' ?
        ['code', 'secrets', 'dependencies', 'license'] :
        ['backup']}
    />;

  const scans =
    <Typography level="body-xs">
      <IconButton onClick={() => navigate(`${config.urls.pages.scans}?scannableResourceId=${scannableResource.id}`)}>
        <TravelExploreIcon />
      </IconButton>
    </Typography>;

  const findings =
    <Typography level="body-xs">
      <IconButton onClick={() => navigate(`${config.urls.pages.findings}?scannableResourceNameFilter=${scannableResource.name}`)}>
        <GrConfigure />
      </IconButton>
    </Typography>;

  const settings =
    <Stack>
      <Box style={{display: 'flex'}}>
        Scan on Push to Branch: {scanOnPushToBranch}
      </Box>
      <Box style={{display: 'flex'}}>
        Periodic Scan: {periodicScan}
      </Box>
      <Box style={{display: 'flex'}}>
        CI Integration: {ciIntegration}
      </Box>
      <Box style={{display: 'flex'}}>
        Writable: {writabilityIcon}
      </Box>
    </Stack>;

  return [
    scannableResource.id,
    scannableResource.name,
    scannableResource.organizationName,
    formatScannableResourceType(scannableResource.scannableResourceType),
    platformInfo,
    coverage,
    scans,
    findings,
    settings,
  ];
}

function getPlatformInfo(scannableResource: ScannableResourceRes) {
  const result = [];

  if(scannableResource.remoteUrl !== '') {
    result.push(getRepositoryType(scannableResource.remoteUrl));
  }

  if(scannableResource.githubRepository !== null) {
    // Might already be included because of the remote URL
    if(!result.includes('github')) {
      result.push('github');
    }
  }

  if(scannableResource.sonarcloudProject !== null) {
    result.push('sonarcloud');
  }

  if(scannableResource.sonatypeApplication !== null) {
    result.push('sonatype');
  }

  if(scannableResource.azureResource !== null) {
    // Might already be included because of the remote URL
    if(!result.includes('msAzure')) {
      result.push('msAzure');
    }
  }

  if(scannableResource.mongodbatlasCluster !== null) {
    result.push('mongodbatlas');
  }

  return result;
}
