import {Box, Button, Typography} from '@mui/joy';
import {queryApi, useSonatypeVisibleScannableResources} from '../../../data/queries';
import type {WebSessionAuthTokenRes, SonatypeApplicationPostReq, SonatypeApplicationRes, SonatypeVisibleRepositoryRes} from '../../../shared/types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {endpoints} from '../../../data/endpoints';
import {getErrorMessage} from '../../../errors';
import {useState} from 'react';
import {OrganizationSelector} from '../../OrganizationSelector';
import {SonatypeAuthentication} from '../../addIntegrationWizard/views/SonatypeAuthentication';

interface Props {
  sonatypeApplications: SonatypeApplicationRes[];
  refetchScannableResources: () => unknown;
}

export function ImportSonatypeApplication({sonatypeApplications, refetchScannableResources}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);
  const {data: visibleRepositories, refetch: refetchVisibleRepositories} =
    useSonatypeVisibleScannableResources(account);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const displayAuthentication = visibleRepositories === null;

  const visibleRepositoriesToDisplay = (visibleRepositories ?? [])
    .filter(isVisibleRepositoryNotSonatypeApplication);

  function isVisibleRepositoryNotSonatypeApplication(visibleRepository: SonatypeVisibleRepositoryRes) {
    return sonatypeApplications
      .find(sonatypeApplication =>
        sonatypeApplication.sonatypeInternalId == visibleRepository.sonatypeInternalId) === undefined;
  }

  async function addSonatypeApplication(visibleRepository: SonatypeVisibleRepositoryRes) {
    const data = {
      sonatypeInternalId: visibleRepository.sonatypeInternalId,
      publicId: visibleRepository.publicId,
      name: visibleRepository.name,
      organizationId: idSelectedOrganization,
      sonatypeIntegrationId: visibleRepository.sonatypeIntegrationId,
    } satisfies SonatypeApplicationPostReq;

    try {
      await queryApi(endpoints.postSonatypeApplication, account, 'post', data);
      setNewNotification('Repository has been imported.');
      await refetchScannableResources();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  if(displayAuthentication) {
    return <SonatypeAuthentication onCompletion={refetchVisibleRepositories} />;
  }

  return <>
    <Box>
      {visibleRepositoriesToDisplay.length === 0 ?
        <Typography>
          There are no visible repositories.
        </Typography> :
        <>
          {visibleRepositoriesToDisplay.map((visibleRepository, i) =>
            <Box key={i}>
              <Button sx={{mb: 1}} onClick={() => addSonatypeApplication(visibleRepository)}>
                add {visibleRepository.name}
              </Button>
            </Box>
          )}

          <OrganizationSelector
            limitToGloballySelectedOrganizationItem={true}
            onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
            organizationTypeFilter={['project']}
          />
        </>
      }
    </Box>
    {
      // TODO: provide description if necessary
    }
    <Box>
      To make (more) sonatype applications visible, ...
    </Box>
  </>;
}
