import {Tooltip} from '@mui/joy';
import {formatCommitHash} from '../utils/textFormatting';
import {TextCopyableByClick} from './TextCopyableByClick';

interface Props {
  commitHash: string;
}

export function CommitHash({commitHash}: Props) {
  // Must use a component like div or Box here because there is a bug that causes
  // Tooltip to just print the text content of a react fragment otherwise. This
  // happens despite the fact that TextCopyableByClick returns a div.
  return <>
    <Tooltip title={commitHash}>
      <div>
        <TextCopyableByClick textToDisplay={formatCommitHash(commitHash)} textToCopy={commitHash} />
      </div>
    </Tooltip>
  </>;
}
