import Button from '@mui/joy/Button';
import {IoMdDownload} from 'react-icons/io';
import {CiImport} from 'react-icons/ci';
import {Table} from '../../../components/table/Table';
import {queryApi, usePolicies} from '../../../data/queries';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import type {WebSessionAuthTokenRes, PolicyRes} from '../../../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom, newNotificationAtom} from '../../../data/atoms';
import type {RowType} from '../../../components/table/ActualTable';
import {CustomModal} from '../../../components/CustomModal';
import {AddPolicy} from '../../../components/AddPolicy';
import {useEffect, useState} from 'react';
import {Badge, Chip, IconButton, Tooltip} from '@mui/joy';
import {formatOrganizationName, formatRuleName} from '../../../utils/textFormatting';
import EditIcon from '@mui/icons-material/Edit';
import {RulesTable} from './RulesTable';
import {endpoints} from '../../../data/endpoints';
import {getErrorMessage} from '../../../errors';
import {ViewPage} from '../../../layout/ViewPage';
import {getOrganizationIdsFromOrganizationItem} from '../../../utils/organizationFiltering';
import {ImportPolicy} from '../../../components/ImportPolicy';
import {AddPolicyExemption} from '../../../components/AddPolicyExemption';
import {config} from '../../../config';

export function Policies() {
  const [pageNumber, setPageNumber] = useState(1);

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);
  const {data: policiesPage, refetch: refetchPolicies} = usePolicies(account,
    getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem), pageNumber);

  const [idOfActiveRow, setIdOfActiveRow] = useState<number | null>(null);
  const activeRow = policiesPage?.items?.find(policy => policy.id === idOfActiveRow) ?? null;

  const [policyImportModalOpen, setPolicyImportModalOpen] = useState(false);
  const [policyAddingModalOpen, setPolicyAddingModalOpen] = useState(false);
  const [policyExemptionPolicyId, setPolicyExemptionPolicyId] = useState<number | null>(null);

  useEffect(() => {
    refetchPolicies().catch(console.error);
  }, [globallySelectedOrganizationItem, pageNumber]);

  // Exit policy edit mode on page change.
  useEffect(() => {
    setIdOfActiveRow(null);
  }, [pageNumber]);

  async function modifyPolicyRuleRelationship(ruleId: number, add: boolean) {
    if(idOfActiveRow === null)
      throw new Error('policy modifiaction attempted without policy being selected');

    const endpoint = add ? endpoints.putPolicyRule : endpoints.deletePolicyRule;
    const httpMethod = add ? 'put' : 'delete';

    const url = endpoint
      .replace('{policyId}', idOfActiveRow.toString())
      .replace('{ruleId}', ruleId.toString());

    try {
      await queryApi(url, account, httpMethod);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  async function deletePolicyExemption(policyExemptionId: number) {
    const url = endpoints.deletePolicyExemption.replace('{id}', policyExemptionId.toString());

    try {
      await queryApi(url, account, 'delete');
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  const topElements = [
    <Button
      color="primary"
      startDecorator={<CiImport />}
      size="sm"
      onClick={() => setPolicyImportModalOpen(true)}
    >
      Import
    </Button>,
    <Button
      color="primary"
      startDecorator={<IoMdDownload />}
      size="sm"
      onClick={() => setPolicyAddingModalOpen(true)}
    >
      New
    </Button>,
  ];

  return <ViewPage title="Policies" topElements={topElements}>
    <Table
      columnTitles={['id', 'Name', 'Organization', 'Exempt Organizations', 'Rules']}
      rows={(policiesPage?.items ?? []).map(policy =>
        getPolicyRow(idOfActiveRow, setIdOfActiveRow, setPolicyExemptionPolicyId, deletePolicyExemption, refetchPolicies, policy))}
      idHighlightedRow={idOfActiveRow ?? undefined}
      pagination={
        {
          pageNumber,
          pageSize: policiesPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: policiesPage?.totalNumberOfItems ?? 0,
        }
      }
    />

    {activeRow !== null &&
      <RulesTable
        key={activeRow.id}
        idsInitiallySelectedRows={activeRow.rules.map(rule => rule.id)}
        onRowCheckboxChange={(ruleId: number, checked: boolean) => {
          modifyPolicyRuleRelationship(ruleId, checked)
            .then(() => refetchPolicies())
            .catch(console.error);
        }}
        organizationIdOverride={activeRow.organizationId}
      />
    }

    <CustomModal
      open={policyImportModalOpen}
      handleClose={() => setPolicyImportModalOpen(false)}
      title="Import Policy"
    >
      <ImportPolicy
        onCompletion={() => {
          setPolicyImportModalOpen(false);
          refetchPolicies().catch(console.error);
        }}
      />
    </CustomModal>

    <CustomModal
      open={policyAddingModalOpen}
      handleClose={() => setPolicyAddingModalOpen(false)}
      title="Add Policy"
    >
      <AddPolicy
        onCompletion={() => {
          setPolicyAddingModalOpen(false);
          refetchPolicies().catch(console.error);
        }}
      />
    </CustomModal>

    {policyExemptionPolicyId !== null &&
      <CustomModal
        open={true}
        handleClose={() => setPolicyExemptionPolicyId(null)}
        title="Add Exemption"
      >
        <AddPolicyExemption
          policyId={policyExemptionPolicyId}
          onCompletion={() => {
            setPolicyExemptionPolicyId(null);
            refetchPolicies().catch(console.error);
          }}
        />
      </CustomModal>
    }
  </ViewPage>;
}

function getPolicyRow(idOfActiveRow: number | null, setIdOfActiveRow: (arg0: number | null) => unknown,
  setPolicyExemptionPolicyId: (arg0: number | null) => unknown,
  deletePolicyExemption: (arg0: number) => Promise<unknown>,
  refetchPolicies: () => Promise<unknown>,
  policy: PolicyRes): RowType {
  const policyExemptions = <>
    {policy.policyExemptions.map((policyExemption, i) =>
      <Badge key={i} badgeContent=<div onClick={() => deletePolicyExemption(policyExemption.id).then(() => refetchPolicies().catch(console.error)).catch(console.error)} style={{cursor: 'pointer', fontSize: 8}}>X</div> size="sm">
        <Tooltip title={policyExemption.organizationName}>
          <Chip sx={{backgroundColor: config.colors.chips.organization, margin: 0.5}}>
            {formatOrganizationName(policyExemption.organizationName)}
          </Chip>
        </Tooltip>
      </Badge>
    )}
    <Tooltip title="add exemption">
      <Chip sx={{margin: 0.5}} onClick={() => setPolicyExemptionPolicyId(policy.id)}>
        +
      </Chip>
    </Tooltip>
  </>;

  const rules = <>
    {policy.rules.map((rule, i) =>
      <Tooltip key={i} title={rule.name}>
        <Chip sx={{backgroundColor: 'cyan', margin: 0.5}}>
          {formatRuleName(rule.name)}
        </Chip>
      </Tooltip>
    )}

    <div style={{display: 'inline-block'}}>
      <IconButton size="sm" onClick={() => setIdOfActiveRow(idOfActiveRow === policy.id ? null : policy.id)}>
        <EditIcon
          sx={{fontSize: 'large', color: idOfActiveRow === policy.id ? 'black' : undefined}}
          style={{transform: 'translate(0, 2px)'}}
        />
      </IconButton>
    </div>
  </>;

  return [
    policy.id,
    policy.name,
    policy.organizationName,
    policyExemptions,
    rules,
  ];
}
