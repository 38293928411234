import {useMsal} from '@azure/msal-react';
import {Login} from './Login';
import {Registration} from './Registration';
import {Button} from '@mui/joy';
import {loginRequest} from '../../../authConfig';

export function LoginAndRegistration() {
  const {instance: msalInstance} = useMsal();

  const handleLoginRedirect = () => {
    msalInstance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return <>
    <Button onClick={handleLoginRedirect}>Sign in via MS Azure</Button>
    <Login />
    <Registration />
  </>;
}
