import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import {Fragment} from 'react';
import {ensureArray} from '../utils/misc';

// false is required to enable optional elements via notation
// {predicate && element}
type AcceptableElement = JSX.Element | string | false;

interface Props {
  title: string;
  topElements?: AcceptableElement[];
  children: AcceptableElement | AcceptableElement[];
}

export function ViewPage({title, topElements, children}: Props) {
  return <>
    <Box
      sx={{
        display: 'flex',
        mb: 1,
        gap: 1,
        flexDirection: {xs: 'column', sm: 'row'},
        alignItems: {xs: 'start', sm: 'center'},
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Typography level="h2" component="h1">
        {title}
      </Typography>

      <div style={{display: 'flex'}}>
        {(topElements ?? []).map((topElement, i) =>
          <div key={i} style={{marginLeft: 5}}>
            {topElement}
          </div>
        )}
      </div>
    </Box>

    {ensureArray(children).map((child, i) =>
      <Fragment key={i}>
        {child}
      </Fragment>
    )}
  </>;
}

