import {useEffect, useState} from 'react';
import {CustomModal} from '../CustomModal';
import {ResourceTypeSelector} from './ResourceTypeSelector';
import type {ScannableResourceRes} from '../../shared/types';

interface Props {
  closeWizard: () => unknown;
  scannableResources: ScannableResourceRes[];
  refetchScannableResources: () => Promise<unknown>;
}

export function ImportResourceWizard({closeWizard, scannableResources, refetchScannableResources}: Props) {
  const [title, setTitle] = useState('Import Resource');
  const [activeComponent, setActiveComponent] = useState(<></>);
  const defaultActiveComponent =
    <ResourceTypeSelector
      setTitle={setTitle}
      setActiveComponent={setActiveComponent}
      scannableResources={scannableResources}
      wizardComponentProps={{
        onCompletion: async () => {
          await refetchScannableResources();
          closeWizard();
        }
      }}
    />;
  useEffect(() => setActiveComponent(defaultActiveComponent), []);

  return <>
    <CustomModal
      open={true}
      handleClose={closeWizard}
      title={title}
    >
      {activeComponent}
    </CustomModal>
  </>;
}
