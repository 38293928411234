import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import ComputerIcon from '@mui/icons-material/Computer';
import {useNavigate} from 'react-router-dom';
import {config} from '../../config';
import {FaUserGroup} from 'react-icons/fa6';
import {IoPersonSharp} from 'react-icons/io5';
import {CgOrganisation} from 'react-icons/cg';
import {FaProjectDiagram} from 'react-icons/fa';
import {TbShieldCheckeredFilled} from 'react-icons/tb';
import {SidebarListPartition} from './SidebarListPartition';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PublicIcon from '@mui/icons-material/Public';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

export function SidebarLowerListPartition() {
  const navigate = useNavigate();

  return <>
    <SidebarListPartition>

      <ListItem nested>
        <ListItemButton>
          <FaUserGroup />
          <ListItemContent>
            <Typography level="title-sm">Administration</Typography>
          </ListItemContent>
        </ListItemButton>
        <List sx={{gap: 0.5}}>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.organigram)}>
              <AccountTreeIcon />
              <ListItemContent>
                <Typography level="title-sm">Organigram</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.organizations)}>
              <CgOrganisation />
              <ListItemContent>
                <Typography level="title-sm">Organizations</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.users)}>
              <IoPersonSharp />
              <ListItemContent>
                <Typography level="title-sm">Users</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.projects)}>
              <FaProjectDiagram />
              <ListItemContent>
                <Typography level="title-sm">Projects</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.devices)}>
              <ComputerIcon />
              <ListItemContent>
                <Typography level="title-sm">Devices</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.resources)}>
              <PublicIcon />
              <ListItemContent>
                <Typography level="title-sm">Resources</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.integrations)}>
              <IntegrationInstructionsIcon />
              <ListItemContent>
                <Typography level="title-sm">Integrations</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.policies)}>
              <TbShieldCheckeredFilled />
              <ListItemContent>
                <Typography level="title-sm">Policies</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate(config.urls.pages.administration.rules)}>
              <TbShieldCheckeredFilled />
              <ListItemContent>
                <Typography level="title-sm">Rules</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
    </SidebarListPartition>
  </>;
}
