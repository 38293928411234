import {Box, Button, Typography} from '@mui/joy';
import {queryApi, useAzureVisibleScannableResources} from '../../../data/queries';
import type {WebSessionAuthTokenRes, AzureResourcePostReq, AzureResourceRes, AzureVisibleScannableResourceRes} from '../../../shared/types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {endpoints} from '../../../data/endpoints';
import {getErrorMessage} from '../../../errors';
import {useState} from 'react';
import {OrganizationSelector} from '../../OrganizationSelector';
import {AzureAuthentication} from '../../addIntegrationWizard/views/AzureAuthentication';

interface Props {
  azureResources: AzureResourceRes[];
  refetchScannableResources: () => unknown;
}

export function ImportAzureResource({azureResources, refetchScannableResources}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);
  const {data: visibleRepositories, refetch: refetchVisibleRepositories} =
    useAzureVisibleScannableResources(account);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const displayAuthentication = visibleRepositories === null;

  const visibleRepositoriesToDisplay = (visibleRepositories ?? [])
    .filter(isVisibleRepositoryNotAzureResource);

  function isVisibleRepositoryNotAzureResource(visibleRepository: AzureVisibleScannableResourceRes) {
    return azureResources
      .find(azureResource => azureResource.resourceId == visibleRepository.resourceId) === undefined;
  }

  async function addAzureResource(visibleScannableResource: AzureVisibleScannableResourceRes) {
    const data = {
      subscriptionId: visibleScannableResource.subscriptionId,
      subscriptionName: visibleScannableResource.subscriptionName,
      resourceId: visibleScannableResource.resourceId,
      resourceName: visibleScannableResource.resourceName,
      organizationId: idSelectedOrganization,
      azureIntegrationId: visibleScannableResource.azureIntegrationId,
    } satisfies AzureResourcePostReq;

    try {
      await queryApi(endpoints.postAzureResource, account, 'post', data);
      setNewNotification('Repository has been imported.');
      await refetchScannableResources();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  if(displayAuthentication) {
    return <AzureAuthentication onCompletion={refetchVisibleRepositories} />;
  }

  return <>
    <Box>
      {visibleRepositoriesToDisplay.length === 0 ?
        <Typography>
          There are no visible resources.
        </Typography> :
        <>
          {visibleRepositoriesToDisplay.map((visibleScannableResource, i) =>
            <Box key={i}>
              <Button sx={{mb: 1}} onClick={() => addAzureResource(visibleScannableResource)}>
                add {visibleScannableResource.subscriptionName}/{visibleScannableResource.resourceName}
              </Button>
            </Box>
          )}

          <OrganizationSelector
            limitToGloballySelectedOrganizationItem={true}
            onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
            organizationTypeFilter={['project']}
          />
        </>
      }
    </Box>
    <Box>
      {/* https://github.com/secguro/secguro-app/issues/493#issuecomment-2232597275 */}
      To make (more) Azure subscriptions visible, authorize your app registration to access additional subscriptions.
    </Box>
  </>;
}
