import {useEffect, useState} from 'react';
import {CustomModal} from '../CustomModal';
import {IntegrationTypeSelector} from './IntegrationTypeSelector';

interface Props {
  closeWizard: () => unknown;
  refetchIntegrations: () => unknown;
}

export function AddIntegrationWizard({closeWizard, refetchIntegrations}: Props) {
  const [title, setTitle] = useState('Add Integration');
  const [activeComponent, setActiveComponent] = useState(<></>);
  const defaultActiveComponent =
    <IntegrationTypeSelector
      setTitle={setTitle}
      setActiveComponent={setActiveComponent}
      wizardComponentProps={{
        onCompletion: async () => {
          await refetchIntegrations();
          closeWizard();
        }
      }}
    />;
  useEffect(() => setActiveComponent(defaultActiveComponent), []);

  return <>
    <CustomModal
      open={true}
      handleClose={closeWizard}
      title={title}
    >
      {activeComponent}
    </CustomModal>
  </>;
}
