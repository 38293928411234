import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import '@fontsource/inter';
import {RecoilRoot} from 'recoil';
import {AppWrapper} from './AppWrapper';

const rootElement = document.getElementById('root');
if(rootElement === null) {
  throw new Error('Root element not found.');
}
const root = createRoot(rootElement);
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <React.StrictMode>
        <AppWrapper />
      </React.StrictMode>
    </RecoilRoot>
  </BrowserRouter>,
);
