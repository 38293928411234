import Button from '@mui/joy/Button';
import {IoMdDownload} from 'react-icons/io';
import {Table} from '../../../components/table/Table';
import {useUsers} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {UserRes, WebSessionAuthTokenRes} from '../../../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../../../data/atoms';
import {useState} from 'react';
import {CustomModal} from '../../../components/CustomModal';
import {AddUser} from '../../../components/AddUser';
import type {RowType} from '../../../components/table/ActualTable';
import {useEffect} from 'react';
import {ViewPage} from '../../../layout/ViewPage';
import {getOrganizationIdsFromOrganizationItem} from '../../../utils/organizationFiltering';
import {BooleanIndicator} from '../../../components/BooleanIndicator';

export function Users() {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState('');

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);

  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const {data: usersPage, refetch: refetchUsers} = useUsers(account,
    getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem), pageNumber, searchString);

  const [userAddingModalOpen, setUserAddingModalOpen] = useState(false);

  useEffect(() => {
    refetchUsers().catch(console.error);
  }, [globallySelectedOrganizationItem, pageNumber, searchString]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchString]);

  const topElements = [
    <Button
      color="primary"
      startDecorator={<IoMdDownload />}
      size="sm"
      onClick={() => setUserAddingModalOpen(true)}
    >
      New
    </Button>,
  ];

  return <ViewPage title="Users" topElements={topElements}>
    <Table
      columnTitles={['id', 'Email Address', 'Is Admin']}
      rows={(usersPage?.items ?? []).toSorted(comparator).map(user => getUserRow(user))}
      onSearchStringChange={setSearchString}
      pagination={
        {
          pageNumber,
          pageSize: usersPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: usersPage?.totalNumberOfItems ?? 0,
        }
      }
    />

    <CustomModal
      open={userAddingModalOpen}
      handleClose={() => setUserAddingModalOpen(false)}
      title="Add User"
    >
      <AddUser
        refetchUsers={refetchUsers}
      />
    </CustomModal>
  </ViewPage>;
}

function comparator(a: UserRes, b: UserRes) {
  return a.id - b.id;
}

function getUserRow(user: UserRes): RowType {
  return [
    user.id,
    user.emailAddress,
    <BooleanIndicator value={user.isAdmin} />
  ];
}
