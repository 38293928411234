import {config} from '../config';

export type RepositoryType = 'github' | 'msAzure' | 'other';

export function inferRepoNameFromRemoteUrl(remoteUrl: string) {
  const githubMatch = getOwnerAndRepoNameFromGithubRemoteUrl(remoteUrl);
  if(githubMatch !== null) {
    const {owner, repoName} = githubMatch;
    return `${owner}/${repoName}`;
  }

  const msAzureMatch = getOwnerAndRepoNameFromMsAzureRemoteUrl(remoteUrl);
  if(msAzureMatch !== null) {
    const {owner, repoName} = msAzureMatch;
    return `${owner}/${repoName}`;
  }

  return null;
}

export function getOwnerAndRepoNameFromGithubRemoteUrl(remoteUrl: string) {
  const githubMatch = remoteUrl.match(config.remoteUrls.githubRemoteUrlRegex);
  if(githubMatch === null)
    return null;

  const owner = githubMatch[1];
  const repoName = githubMatch[2];

  return {owner, repoName};
}

export function getOwnerAndRepoNameFromMsAzureRemoteUrl(remoteUrl: string) {
  const msAzureMatch = remoteUrl.match(config.remoteUrls.msAzureUrlRegex);
  if(msAzureMatch === null)
    return null;

  const owner = msAzureMatch[1];
  const repoName = msAzureMatch[2];

  return {owner, repoName};
}

export function isRemoteUrlValid(remoteUrl: string) {
  const isProtocolValid = remoteUrl.startsWith('http://') ||
    remoteUrl.startsWith('https://') ||
    remoteUrl.match(/@.+\..+:/) !== null;
  const hasPlausibleMinumumLength = remoteUrl.length >= config.remoteUrls.minimumLength;

  return isProtocolValid && hasPlausibleMinumumLength;
}

export function getRepositoryType(remoteUrl: string): RepositoryType {
  if(getOwnerAndRepoNameFromGithubRemoteUrl(remoteUrl) !== null)
    return 'github';

  if(getOwnerAndRepoNameFromMsAzureRemoteUrl(remoteUrl) !== null)
    return 'msAzure';

  return 'other';
}
