import {useState} from 'react';
import MuiTable from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import {config} from '../../config';
import {Tooltip} from '@mui/joy';
import {useSetRecoilState} from 'recoil';
import {clipboardAtom} from '../../data/atoms';

export type CellType = string | JSX.Element;
export type RowType = [number, ...CellType[]];

interface Props {
  columnTitles: string[];
  rows: RowType[];
  idHighlightedRow?: number;
  idsInitiallySelectedRows?: number[];
  onRowCheckboxChange?: (rowId: number, checked: boolean) => unknown;
}

export function ActualTable({columnTitles, rows,
  idHighlightedRow, idsInitiallySelectedRows, onRowCheckboxChange}: Props) {
  const setClipboard = useSetRecoilState(clipboardAtom);

  const [selected, setSelected] = useState<readonly number[]>(idsInitiallySelectedRows ?? []);

  return <>
    <Sheet
      className="OrderTableContainer"
      variant="outlined"
      sx={{
        display: {xs: 'none', sm: 'initial'},
        width: '100%',
        borderRadius: 'sm',
        flexShrink: 1,
        overflow: 'auto',
        minHeight: (Math.min(rows.length, 3) + 1) * 40,
      }}
    >
      <MuiTable
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <thead>
          <tr>
            <th style={{width: 48, textAlign: 'center', padding: '12px 6px'}}>
              <Checkbox
                size="sm"
                indeterminate={
                  selected.length > 0 && selected.length !== rows.length
                }
                checked={selected.length === rows.length}
                onChange={(event) => {
                  // Just to make it conceptually easier to think about yet to provide immediate
                  // visual feedback to the user.
                  const selectedBefore = [...selected];

                  setSelected(
                    event.target.checked ? rows.map((row) => row[0]) : [],
                  );

                  if(onRowCheckboxChange !== undefined) {
                    for(const row of rows) {
                      if(selectedBefore.includes(row[0]) === event.target.checked)
                        continue;

                      onRowCheckboxChange(row[0], event.target.checked);
                    }
                  }
                }}
                color={
                  selected.length > 0 || selected.length === rows.length
                    ? 'primary'
                    : undefined
                }
                sx={{verticalAlign: 'text-bottom'}}
              />
            </th>
            {
              columnTitles.slice(1).map((columnTitle, i) =>
                <th key={i} style={{width: 140, padding: '12px 6px', zIndex: 1}}>{columnTitle}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr
              key={row[0]}
              style={{
                backgroundColor: row[0] === idHighlightedRow ?
                  config.colors.highlighting.tableRow :
                  undefined,
              }}
            >
              <td style={{textAlign: 'center', width: 120}}>
                <Checkbox
                  size="sm"
                  checked={selected.includes(row[0])}
                  color={selected.includes(row[0]) ? 'primary' : undefined}
                  onChange={(event) => {
                    setSelected((ids) =>
                      event.target.checked
                        ? ids.concat(row[0])
                        : ids.filter((itemId) => itemId !== row[0]),
                    );

                    if(onRowCheckboxChange !== undefined) {
                      onRowCheckboxChange(row[0], event.target.checked);
                    }
                  }}
                  slotProps={{checkbox: {sx: {textAlign: 'left'}}}}
                  sx={{verticalAlign: 'text-bottom'}}
                />
              </td>
              {row.slice(1).map((cell, i) =>
                <td key={i} onClick={typeof cell === 'string' ? (() => setClipboard(cell)) : undefined}>
                  {(() =>
                    typeof cell === 'string' ?
                      <Tooltip title={cell}>
                        <Typography
                          level="body-xs"
                          sx={{
                            display: 'inline-block',
                            maxWidth: '150px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {cell}
                        </Typography>
                      </Tooltip> :
                      cell
                  )()}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </MuiTable>
    </Sheet>
  </>;
}
