import {Button, Input, Option, Select, Typography} from '@mui/joy';
import {useState} from 'react';
import type {WebSessionAuthTokenRes, OrganizationPostReq, OrganizationType} from '../shared/types';
import {assertNever} from '../utils/misc';
import {endpoints} from '../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../data/atoms';
import {getErrorMessage} from '../errors';
import {queryApi} from '../data/queries';

interface Props {
  idParentOfNewOrganization: number;
  typeParentOfNewOrganization: OrganizationType;
  refetchOrganizationTree: () => unknown;
}

export function AddOrganization({idParentOfNewOrganization, typeParentOfNewOrganization,
  refetchOrganizationTree}: Props) {
  const availableOrganizationTypes: OrganizationType[] = (() => {
    switch(typeParentOfNewOrganization) {
      case 'organization':
        return ['organization', 'team'];
      case 'team':
        return ['project'];
      case 'project':
        return ['app'];
      case 'app':
        return [];
      default:
        assertNever(typeParentOfNewOrganization);
    }
  })();

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [name, setName] = useState('');
  const [selectedOrganizationType, setSelectedOrganizationType] = useState(availableOrganizationTypes[0]);

  async function addOrganization() {
    const data = {
      parentOrganizationId: idParentOfNewOrganization,
      name,
      organizationType: selectedOrganizationType,
    } satisfies OrganizationPostReq;

    try {
      await queryApi(endpoints.postOrganizations, account, 'post', data);
      setNewNotification('Organization has been created.');
      await refetchOrganizationTree();

      setName('');
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  function handleSelectionChange(_: unknown, organizationTypeSelected: string | number | null) {
    // Happens on load.
    if(organizationTypeSelected === null)
      return;

    const organizationType = availableOrganizationTypes.find(ot => ot === organizationTypeSelected);
    if(organizationType === undefined)
      throw new Error('did not receive an organization type: ' + organizationTypeSelected);

    setSelectedOrganizationType(organizationType);
  }

  return <>
    <Typography sx={{marginBottom: 2}}>
      Please choose a name for the new organizational entity.
    </Typography>
    <Input
      sx={{marginBottom: 2}}
      placeholder="name"
      value={name}
      onChange={e => setName(e.target.value)}
    />
    <Select
      sx={{marginBottom: 2}}
      size="sm"
      placeholder="Select type of organizational entity"
      value={selectedOrganizationType}
      onChange={handleSelectionChange}
    >
      {availableOrganizationTypes.map(organizationType =>
        <Option
          key={organizationType}
          value={organizationType}
        >
          {organizationType}
        </Option>
      )}
    </Select>
    <Button
      disabled={name === ''}
      onClick={addOrganization}
    >
      Submit
    </Button>
  </>;
}

