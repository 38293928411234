import {Box, Button, Typography} from '@mui/joy';
import {useState} from 'react';
import type {WebSessionAuthTokenRes, PredefinedPolicyImportReq} from '../shared/types';
import {endpoints} from '../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../data/atoms';
import {getErrorMessage} from '../errors';
import {queryApi, usePredefinedPolicies} from '../data/queries';
import {OrganizationSelector} from './OrganizationSelector';

interface Props {
  onCompletion: () => unknown;
}

export function ImportPolicy({onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const {data: predefinedPolicies} = usePredefinedPolicies(account);

  async function importPolicy(predefinedPolicyId: number) {
    const data = {
      predefinedPolicyId,
      organizationId: idSelectedOrganization,
    } satisfies PredefinedPolicyImportReq;

    try {
      await queryApi(endpoints.postImportPredefinedPolicy, account, 'post', data);
      setNewNotification('Policy has been imported.');
      await onCompletion();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Typography sx={{marginBottom: 2}}>
      Please choose which predefined policy to import.
    </Typography>
    {(predefinedPolicies ?? []).map((predefinedPolicy, i) =>
      <Box key={i}>
        <Button sx={{mb: 1}} onClick={() => importPolicy(predefinedPolicy.id)}>
          {predefinedPolicy.name}
        </Button>
      </Box>
    )}
    <OrganizationSelector
      limitToGloballySelectedOrganizationItem={true}
      onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
      organizationTypeFilter={['organization', 'team', 'project', 'app']}
    />
  </>;
}

