import {Table} from '../../../components/table/Table';
import {useDevices} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {WebSessionAuthTokenRes, DeviceRes} from '../../../shared/types';
import {accountAtom} from '../../../data/atoms';
// @ts-expect-error There does not seem to be a more perfect library than this one (small, no further dependencies, no weird behavior, easy to use, uses the html time tag), and given its limited use, I think no types is fine. The only somewhat viable alternative I've found that does have types is: @yaireo/relative-time
import RelativeTime from 'react-relative-time';
import {CustomModal} from '../../../components/CustomModal';
import {useNavigate} from 'react-router-dom';
import {config} from '../../../config';
import {AddDevice} from '../../../components/AddDevice';
import type {RowType} from '../../../components/table/ActualTable';
import {useEffect, useState} from 'react';
import {ViewPage} from '../../../layout/ViewPage';

export function Devices() {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState('');

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const deviceRegistrationId = urlParams.get('deviceRegistrationId');

  const {data: devicesPage, refetch: refetchDevices} = useDevices(account, pageNumber, searchString);
  useEffect(() => {
    refetchDevices().catch(console.error);
  }, [pageNumber, searchString]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchString]);

  function redirectToUnparameterizedPage() {
    navigate(config.urls.pages.administration.devices);
  }

  return <ViewPage title="Devices">
    <Table
      columnTitles={['id', 'Name', 'Date Added']}
      rows={(devicesPage?.items ?? []).toSorted(comparator).map(device => getDeviceRow(device))}
      onSearchStringChange={setSearchString}
      pagination={
        {
          pageNumber,
          pageSize: devicesPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: devicesPage?.totalNumberOfItems ?? 0,
        }
      }
    />

    {deviceRegistrationId !== null &&
      <CustomModal
        open={true}
        handleClose={redirectToUnparameterizedPage}
        title="Add Device"
      >
        <AddDevice
          deviceRegistrationId={deviceRegistrationId}
          finalize={() => {refetchDevices().catch(console.error); redirectToUnparameterizedPage();}}
        />
      </CustomModal>
    }
  </ViewPage>;
}

function comparator(a: DeviceRes, b: DeviceRes) {
  return a.dateAddedInMs - b.dateAddedInMs;
}

function getDeviceRow(device: DeviceRes): RowType {
  const dateAdded = <RelativeTime value={device.dateAddedInMs} />;

  return [
    device.id,
    device.name,
    dateAdded,
  ];
}
