import {Table} from '../../../components/table/Table';
import {useRuleViolations} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {WebSessionAuthTokenRes, RuleRes} from '../../../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../../../data/atoms';
import {useEffect, useState} from 'react';
import type {RowType} from '../../../components/table/ActualTable';
import {Badge, Chip} from '@mui/joy';
import {getOrganizationIdsFromOrganizationItem} from '../../../utils/organizationFiltering';
import {config} from '../../../config';

interface Props {
  idsInitiallySelectedRows?: number[];
  onRowCheckboxChange?: (rowId: number, checked: boolean) => unknown;
}

export function RuleViolationsTable({idsInitiallySelectedRows, onRowCheckboxChange}: Props) {
  const [pageNumber, setPageNumber] = useState(1);

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const {data: ruleViolationsPage, refetch: refetchRuleViolations} = useRuleViolations(account,
    getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem), pageNumber);
  useEffect(() => {
    refetchRuleViolations().catch(console.error);
  }, [globallySelectedOrganizationItem, pageNumber]);

  return <>
    <Table
      columnTitles={['id', 'Name of External Platform', 'ID on External Platform',
        'Name', 'Scope', 'Severity', 'Violations']}
      rows={(ruleViolationsPage?.items ?? []).map(ruleViolation => getRuleViolationRow(ruleViolation))}
      idsInitiallySelectedRows={idsInitiallySelectedRows}
      onRowCheckboxChange={onRowCheckboxChange}
      pagination={
        {
          pageNumber,
          pageSize: ruleViolationsPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: ruleViolationsPage?.totalNumberOfItems ?? 0,
        }
      }
    />
  </>;
}

function getRuleViolationRow(ruleViolation: RuleRes): RowType {
  const violations = <>
    {ruleViolation.ruleViolationCounts.map((ruleViolationCount, i) =>
      <Badge key={i} badgeContent={ruleViolationCount.numberOfViolations} size="sm">
        <Chip sx={{backgroundColor: config.colors.chips.scannableResource, margin: 0.5}}>
          {ruleViolationCount.scannableResourceName}
        </Chip>
      </Badge>
    )
    }
  </>;

  return [
    ruleViolation.id,
    ruleViolation.externalPlatform,
    ruleViolation.idOnExternalPlatform,
    ruleViolation.name,
    ruleViolation.scope,
    ruleViolation.severity,
    violations,
  ];
}
