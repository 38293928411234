import {useEffect, useState} from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import {listItemButtonClasses} from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {MdLogout} from 'react-icons/md';
import {MdBrightnessAuto} from 'react-icons/md';
import {ColorSchemeToggle} from './ColorSchemeToggle';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../../data/atoms';
import {useSidebarInformation, useUser} from '../../data/queries';
import {getErrorMessage} from '../../errors';
import {SidebarUpperListPartition} from './SidebarUpperListPartition';
import {SidebarLowerListPartition} from './SidebarLowerListPartition';
import {OrganizationSelector} from '../../components/OrganizationSelector';
import {useMsal} from '@azure/msal-react';

export function Sidebar() {
  const {instance: msalInstance} = useMsal();

  const [account, setAccount] = useRecoilState(accountAtom);
  const setGloballySelectedOrganizationItem = useSetRecoilState(globallySelectedOrganizationItemAtom);

  const {data: sidebarInformationRaw, refetch: refetchSidebarInformation,
    remove: removeSidebarInformation} = useSidebarInformation(account);
  const [sidebarInformation, setSidebarInformation] = useState(sidebarInformationRaw);

  const {data: userRaw, refetch: refetchUser,
    remove: removeUser, error: userLoadingError} = useUser(account);
  const [user, setUser] = useState(userRaw);

  // Mark user as logged out after DB-re-seeding.
  useEffect(() => {
    if(account !== null &&
      getErrorMessage(userLoadingError) === 'set valid auth token in header field Authorization') {
      setAccount(null);
    }
  }, [userLoadingError]);

  useEffect(() => {
    if(account === null)
      return;

    // Calling removeUser() is necessary because otherwise that stupid querying library
    // would just keep on trying to fetch the user using the old / nonexistent
    // credentials instead of using the new new credentials supplied to useUser().
    removeUser();
    refetchUser().catch(console.error);
  }, [account]);

  // Confer useEffect() that calls removeUser().
  useEffect(() => {
    if(account === null)
      return;

    removeSidebarInformation();
    refetchSidebarInformation().catch(console.error);
  }, [account]);

  // Hide user information immediately upon logout.
  // Cannot optimize this away by calling removeUser(), because for some reason,
  // that stupid querying library will not cause a re-render when this function
  // is called.
  useEffect(() => {
    if(account === null) {
      setUser(undefined);
      return;
    }

    setUser(userRaw);
  }, [userRaw, account]);

  useEffect(() => {
    if(account === null) {
      setSidebarInformation(undefined);
      return;
    }

    setSidebarInformation(sidebarInformationRaw);
  }, [sidebarInformationRaw, account]);

  const handleLogoutRedirect = () => {
    msalInstance.logoutRedirect().catch((error) => console.log(error));
  };

  function logout() {
    if(account === null)
      return;

    if(account.provider === 'microsoft') {
      handleLogoutRedirect();
    }

    setAccount(null);
  }

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {xs: 'fixed', md: 'sticky'},
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <IconButton variant="soft" color="primary" size="sm">
          <MdBrightnessAuto />
        </IconButton>
        <Typography level="title-lg">Secguro</Typography>
        <ColorSchemeToggle />
      </Box>

      <OrganizationSelector
        limitToGloballySelectedOrganizationItem={false}
        onSelect={selectedOrganizationItem => setGloballySelectedOrganizationItem(selectedOrganizationItem)}
      />

      <Box
        sx={{
          marginTop: -2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <SidebarUpperListPartition sidebarInformation={sidebarInformation} />
        <SidebarLowerListPartition />
      </Box>


      <Divider />
      <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <Avatar
          variant="outlined"
          size="sm"
          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
        />
        {user !== undefined &&
          <Box sx={{minWidth: 0, flex: 1}}>
            <Typography level="title-sm">{user.emailAddress}</Typography>
            <Typography level="body-xs">{user.emailAddress}</Typography>
          </Box>
        }
        <IconButton size="sm" variant="plain" color="neutral">
          <MdLogout onClick={logout} />
        </IconButton>
      </Box>
    </Sheet>
  );
}

export function openSidebar() {
  if(typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
}

export function closeSidebar() {
  if(typeof window !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
}

export function toggleSidebar() {
  if(typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if(slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}
