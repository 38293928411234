import {Table} from '../../../components/table/Table';
import {useOrganizationsTree} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {WebSessionAuthTokenRes} from '../../../shared/types';
import {accountAtom} from '../../../data/atoms';
import type {RowType} from '../../../components/table/ActualTable';
import {ViewPage} from '../../../layout/ViewPage';
import type {OrganizationsListItem} from '../../../components/OrganizationSelector';
import {getOrganizationsListFromOrganizationTree} from '../../../components/OrganizationSelector';
import {UsersChips} from '../../../components/UsersChips';

export function Organizations() {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);

  const {data: organizationsTree} = useOrganizationsTree(account);

  const organizationItems = getOrganizationsListFromOrganizationTree(organizationsTree);

  return <ViewPage title="Organizations">
    <Table
      columnTitles={['id', 'Name', 'Type', 'Users']}
      rows={(organizationItems ?? [])
        // Do not display organizations of type 'project' in orginizations table.
        .filter(organization => organization.organizationType !== 'project')
        .map(organization => getOrganizationRow(organization))
      }
    />
  </ViewPage>;
}

function getOrganizationRow(organization: OrganizationsListItem): RowType {
  const usersChips = <UsersChips organizationId={organization.id} />;

  return [
    organization.id,
    organization.name,
    organization.organizationType,
    usersChips,
  ];
}
