import Button from '@mui/joy/Button';
import {IoMdDownload} from 'react-icons/io';
import {RulesTable} from './RulesTable';
import {ViewPage} from '../../../layout/ViewPage';

export function Rules() {
  const topElements = [
    <Button
      color="primary"
      startDecorator={<IoMdDownload />}
      size="sm"
    >
      New
    </Button>,
  ];

  return <ViewPage title="Rules" topElements={topElements}>
    <RulesTable />
  </ViewPage>;
}

