import {useEffect, useState} from 'react';
import {useColorScheme} from '@mui/joy/styles';
import type {IconButtonProps} from '@mui/joy/IconButton';
import IconButton from '@mui/joy/IconButton';
import {MdDarkMode} from 'react-icons/md';
import {MdLightMode} from 'react-icons/md';

export function ColorSchemeToggle(props: IconButtonProps) {
  const {onClick, ...other} = props;
  const {mode, setMode} = useColorScheme();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  if(!mounted) {
    return (
      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        {...other}
        sx={{ml: 'auto'}}
        disabled
      />
    );
  }
  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="neutral"
      {...other}
      onClick={(event) => {
        if(mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
        onClick?.(event);
      }}
      sx={[
        {
          ml: 'auto',
          '& > *:first-of-type': {
            display: mode === 'dark' ? 'none' : 'initial',
          },
          '& > *:last-of-type': {
            display: mode === 'light' ? 'none' : 'initial',
          },
        },
      ]}
    >
      <MdDarkMode />
      <MdLightMode />
    </IconButton>
  );
}
