import {Button, Input, Typography} from '@mui/joy';
import {useEffect, useState} from 'react';
import {inferRepoNameFromRemoteUrl, isRemoteUrlValid} from '../../../utils/repositoryRemoteUrls';
import type {WebSessionAuthTokenRes, ScannableResourcePostReq} from '../../../shared/types';
import {endpoints} from '../../../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {getErrorMessage} from '../../../errors';
import {OrganizationSelector} from '../../OrganizationSelector';
import {queryApi} from '../../../data/queries';

interface Props {
  onCompletion: () => unknown;
}

export function ImportSshHttpsRepository({onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  const [name, setName] = useState('');
  const [remoteUrl, setRemoteUrl] = useState('');

  useEffect(() => {
    const inferredName = inferRepoNameFromRemoteUrl(remoteUrl);
    if(inferredName === null)
      return;

    setName(inferredName);
  }, [remoteUrl]);

  async function addScannableResource() {
    const data = {
      scannableResourceName: name,
      scannableResourceRemoteUrl: remoteUrl,
      organizationId: idSelectedOrganization,
    } satisfies ScannableResourcePostReq;

    try {
      await queryApi(endpoints.postScannableResources, account, 'post', data);
      setNewNotification('Repository has been added.');
      await onCompletion();

      setName('');
      setRemoteUrl('');
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Typography sx={{marginBottom: 2}}>
      Repositories you add here will not have the feateres "Scan on Push to Branch" and
      "Periodic Scan". If you need these features, please close this dialog and choose
      "Import from GitHub".
    </Typography>
    <Input
      sx={{marginBottom: 2}}
      placeholder="name (optional)"
      value={name}
      onChange={e => setName(e.target.value)}
    />
    <Input
      sx={{marginBottom: 2}}
      placeholder="remote URL"
      value={remoteUrl}
      onChange={e => setRemoteUrl(e.target.value)}
    />
    <OrganizationSelector
      limitToGloballySelectedOrganizationItem={true}
      onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
      organizationTypeFilter={['project']}
    />
    <Button
      disabled={!isRemoteUrlValid(remoteUrl)}
      onClick={addScannableResource}
    >
      Submit
    </Button>
  </>;
}
