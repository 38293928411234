import Button from '@mui/joy/Button';
import {IoMdDownload} from 'react-icons/io';
import {RuleViolationsTable} from './RuleViolationsTable';
import {ViewPage} from '../../../layout/ViewPage';

export function RuleViolations() {
  const topElements = [
    <Button
      color="primary"
      startDecorator={<IoMdDownload />}
      size="sm"
    >
      New
    </Button>,
  ];

  return <ViewPage title="Rule Violations" topElements={topElements}>
    <RuleViolationsTable />
  </ViewPage>;
}

