import {Table} from '../../../components/table/Table';
import {useOrganizationsTree, useRules} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {WebSessionAuthTokenRes, RuleRes} from '../../../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../../../data/atoms';
import {useEffect, useState} from 'react';
import type {RowType} from '../../../components/table/ActualTable';
import {Badge, Chip} from '@mui/joy';
import {getOrganizationIdsFromOrganizationItem} from '../../../utils/organizationFiltering';
import type {OrganizationsListItem} from '../../../components/OrganizationSelector';
import {getOrganizationsListFromOrganizationTree} from '../../../components/OrganizationSelector';
import {config} from '../../../config';

interface Props {
  idsInitiallySelectedRows?: number[];
  onRowCheckboxChange?: (rowId: number, checked: boolean) => unknown;
  organizationIdOverride?: number;
}

export function RulesTable({idsInitiallySelectedRows, onRowCheckboxChange,
  organizationIdOverride}: Props) {
  const [pageNumber, setPageNumber] = useState(1);

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const {data: organizationsTree} = useOrganizationsTree(account);
  const organizationItem = organizationIdOverride === undefined ?
    globallySelectedOrganizationItem :
    limitOrganizationItemToSubtree(organizationIdOverride,
      getOrganizationsListFromOrganizationTree(organizationsTree));

  const {data: rulesPage, refetch: refetchRules} = useRules(account,
    getOrganizationIdsFromOrganizationItem(organizationItem), pageNumber);
  useEffect(() => {
    refetchRules().catch(console.error);
  }, [globallySelectedOrganizationItem, pageNumber]);

  return <>
    <Table
      columnTitles={['id', 'Name of External Platform', 'ID on External Platform',
        'Name', 'Scope', 'Severity', 'Violations']}
      rows={(rulesPage?.items ?? []).map(rule => getRuleRow(rule))}
      idsInitiallySelectedRows={idsInitiallySelectedRows}
      onRowCheckboxChange={onRowCheckboxChange}
      pagination={
        {
          pageNumber,
          pageSize: rulesPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: rulesPage?.totalNumberOfItems ?? 0,
        }
      }
    />
  </>;
}

function getRuleRow(rule: RuleRes): RowType {
  const violations = <>
    {rule.ruleViolationCounts.map((ruleViolationCount, i) =>
      <Badge key={i} badgeContent={ruleViolationCount.numberOfViolations} size="sm">
        <Chip sx={{backgroundColor: config.colors.chips.scannableResource, margin: 0.5}}>
          {ruleViolationCount.scannableResourceName}
        </Chip>
      </Badge>
    )
    }
  </>;

  return [
    rule.id,
    rule.externalPlatform,
    rule.idOnExternalPlatform,
    rule.name,
    rule.scope,
    rule.severity,
    violations,
  ];
}

function limitOrganizationItemToSubtree(organizationId: number,
  organizationListItems: OrganizationsListItem[]) {
  return organizationListItems.find(oli => oli.id === organizationId) ?? null;
}
