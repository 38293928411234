import {Chip} from '@mui/joy';

interface Props {
  label: string;
  count: number;
}

export function CoverageChip({label, count}: Props) {
  const annotation = count < 2 ? '' : ` (${count})`;

  return <>
    <Chip sx={{backgroundColor: count === 0 ? 'red' : 'lightgreen', margin: 0.5}}>
      {label}{annotation}
    </Chip>
  </>;
}
