import {Button, Typography} from '@mui/joy';
import {useState} from 'react';
import type {WebSessionAuthTokenRes, PolicyExemptionPostReq} from '../shared/types';
import {endpoints} from '../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../data/atoms';
import {getErrorMessage} from '../errors';
import {queryApi} from '../data/queries';
import {OrganizationSelector} from './OrganizationSelector';

interface Props {
  policyId: number;
  onCompletion: () => unknown;
}

export function AddPolicyExemption({policyId, onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [idSelectedOrganization, setIdSelectedOrganization] = useState(-1);

  async function addPolicy() {
    const data = {
      policyId,
      organizationId: idSelectedOrganization,
    } satisfies PolicyExemptionPostReq;

    try {
      await queryApi(endpoints.postPolicyExemptions, account, 'post', data);
      setNewNotification('Policy has been created.');
      await onCompletion();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Typography sx={{marginBottom: 2}}>
      Please choose which organization (and its sub-organizations) to exempt.
    </Typography>
    <OrganizationSelector
      limitToGloballySelectedOrganizationItem={true}
      onSelect={organizationsListItem => setIdSelectedOrganization(organizationsListItem.id)}
      organizationTypeFilter={['organization', 'team']}
    />
    <Button
      onClick={addPolicy}
    >
      Submit
    </Button>
  </>;
}

