import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import {IoMdDownload} from 'react-icons/io';
import {useLocation} from 'react-router';
import {Table} from '../../../components/table/Table';
import {useRecoilValue} from 'recoil';
import type {WebSessionAuthTokenRes, FindingRes} from '../../../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../../../data/atoms';
import {useFindings, useScannableResourcesMinimal} from '../../../data/queries';
import {useEffect, useState} from 'react';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import {Chip, IconButton, Tooltip} from '@mui/joy';
import {CustomModal} from '../../../components/CustomModal';
import {config} from '../../../config';
// @ts-expect-error There does not seem to be a more perfect library than this one (small, no further dependencies, no weird behavior, easy to use, uses the html time tag), and given its limited use, I think no types is fine. The only somewhat viable alternative I've found that does have types is: @yaireo/relative-time
import RelativeTime from 'react-relative-time';
import {formatLocation} from '../../../utils/textFormatting';
import type {RowType} from '../../../components/table/ActualTable';
import {Filter} from '../../../components/table/Filter';
import {FindingDetails} from '../../../components/FindingDetails';
import {getOrganizationIdsFromOrganizationItem} from '../../../utils/organizationFiltering';

export function Findings() {
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);

  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [scannableResourceNameFilter, setScannableResourceNameFilter] =
    useState(urlParams.get('scannableResourceNameFilter') ?? '');
  const [severityFilter, setSeverityFilter] = useState('');

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);

  const scanId = urlParams.get('scanId');

  const {data: scannableResourcesMinimal} = useScannableResourcesMinimal(account);
  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const {data: findingsPage, refetch: refetchFindings} = useFindings(account,
    getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem), pageNumber, searchString,
    scannableResourceNameFilter, severityFilter, scanId);

  const [selectedFinding, setSelectedFinding] = useState<FindingRes | null>(null);

  const [modalWide, setModalWide] = useState(false);

  // Re-fetch findings on URL change or search query change.
  useEffect(() => {
    refetchFindings().catch(console.error);
  }, [location, globallySelectedOrganizationItem,
    pageNumber, searchString, scannableResourceNameFilter, severityFilter]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchString, scannableResourceNameFilter, severityFilter]);

  const filters = [
    <Filter
      title="Severity"
      onChange={v => setSeverityFilter(v ?? '')}
      options={[
        {
          id: 'ERROR',
          label: 'error',
        },
        {
          id: 'WARNING',
          label: 'warning',
        },
        {
          id: 'INFO',
          label: 'info',
        },
      ]}
    />,
    <Filter
      title="ScannableResource"
      onChange={v => setScannableResourceNameFilter(v ?? '')}
      options={(scannableResourcesMinimal ?? []).map(scannableResource => ({
        id: scannableResource.name,
      }))}
    />,
  ];

  return <>
    <Box
      sx={{
        display: 'flex',
        mb: 1,
        gap: 1,
        flexDirection: {xs: 'column', sm: 'row'},
        alignItems: {xs: 'start', sm: 'center'},
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Typography level="h2" component="h1">
        Findings
      </Typography>
      <Button
        color="primary"
        startDecorator={<IoMdDownload />}
        size="sm"
      >
        New
      </Button>
    </Box>
    <Table
      filters={filters}
      columnTitles={['id', 'Repository', 'Latest Detection', 'Category', 'Severity',
        'Detector', 'Rule', 'Location', 'Match', 'Hint', 'Details']}
      rows={(findingsPage?.items ?? []).map(finding => getFindingRow(setSelectedFinding, finding))}
      onSearchStringChange={setSearchString}
      pagination={
        {
          pageNumber,
          pageSize: findingsPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: findingsPage?.totalNumberOfItems ?? 0,
        }
      }
    />

    {selectedFinding !== null &&
      <CustomModal
        open={true}
        handleClose={() => {
          setSelectedFinding(null);
        }}
        title={`Finding ${selectedFinding.id}`}
        wide={modalWide}
      >
        <FindingDetails
          finding={selectedFinding}
          setModalWide={setModalWide}
        />
      </CustomModal >
    }
  </>;
}

function getFindingRow(setSelectedFinding: (finding: FindingRes) => unknown, finding: FindingRes): RowType {
  const latestDetection = <RelativeTime value={finding.latestDetectionDateInMs} />;

  const severityKey = ((): keyof typeof config.colors.severity => {
    switch(finding.severity) {
      case 'ERROR':
        return 'error';
      case 'WARNING':
        return 'warning';
      case 'INFO':
        return 'info';
      default:
        throw new Error('unexpected value for severity: ' + finding.severity);
    }
  })();
  const severity = <Tooltip title={severityKey}>
    <Chip sx={{backgroundColor: config.colors.severity[severityKey], margin: 0.5}}>
      {severityKey}
    </Chip>
  </Tooltip>;

  const location = formatLocation(finding.file, finding.lineStart, finding.columnStart);
  const locationForTable = location !== null ? location :
    <Typography level="body-xs" fontStyle="italic">(does not exist)</Typography>;

  const details =
    <Typography level="body-xs">
      <IconButton onClick={() => setSelectedFinding(finding)}>
        <ReadMoreIcon />
      </IconButton>
    </Typography>;

  return [
    finding.id,
    finding.scannableResourceName,
    latestDetection,
    finding.category,
    severity,
    finding.detector,
    finding.rule,
    locationForTable,
    finding.match,
    finding.hint,
    details
  ];
}
