import Button from '@mui/joy/Button';
import {IoMdDownload} from 'react-icons/io';
import {Table} from '../../../components/table/Table';
import {ViewPage} from '../../../layout/ViewPage';

export function Tasks() {
  const topElements = [
    <Button
      color="primary"
      startDecorator={<IoMdDownload />}
      size="sm"
    >
      New
    </Button>,
  ];

  return <ViewPage title="Tasks" topElements={topElements}>
    <Table
      columnTitles={['id']}
      rows={[]}
    />
  </ViewPage>;
}
