import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import {Table} from '../../../components/table/Table';
import {useScans} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {WebSessionAuthTokenRes, ScanRes} from '../../../shared/types';
import {accountAtom} from '../../../data/atoms';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import {Badge, Chip, IconButton, Tooltip} from '@mui/joy';
import type {NavigateFunction} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
// @ts-expect-error There does not seem to be a more perfect library than this one (small, no further dependencies, no weird behavior, easy to use, uses the html time tag), and given its limited use, I think no types is fine. The only somewhat viable alternative I've found that does have types is: @yaireo/relative-time
import RelativeTime from 'react-relative-time';
import {config} from '../../../config';
import {OngoingScansHint} from '../../../components/OngoingScansHint';
import {formatArray} from '../../../utils/textFormatting';
import {CommitHash} from '../../../components/CommitHash';
import {useEffect, useState} from 'react';
import type {RowType} from '../../../components/table/ActualTable';
import {ViewPage} from '../../../layout/ViewPage';

export function Scans() {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const scannableResourceIdString = urlParams.get('scannableResourceId');
  if(scannableResourceIdString === null)
    throw new Error('scannableResourceId not found');
  const scannableResourceId = parseInt(scannableResourceIdString);

  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState('');

  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const {data: scansPage, refetch: refetchScans} =
    useScans(account, scannableResourceId, pageNumber, searchString);
  useEffect(() => {
    refetchScans().catch(console.error);
  }, [pageNumber, searchString]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchString]);

  return <ViewPage title="Scans">
    <OngoingScansHint />
    <Table
      columnTitles={['id', 'Date', 'Repository', 'Branch', 'Revision', 'User', 'Device',
        'Number of Findings', 'Risk', 'Details']}
      rows={(scansPage?.items ?? []).map(scan => getScanRow(navigate, scan))}
      onSearchStringChange={setSearchString}
      pagination={
        {
          pageNumber,
          pageSize: scansPage?.pageSize ?? 1,
          setPageNumber,
          totalNumberOfItems: scansPage?.totalNumberOfItems ?? 0,
        }
      }
    />
  </ViewPage>;
}

function getScanRow(navigate: NavigateFunction, scan: ScanRes): RowType {
  const date = <RelativeTime value={scan.dateInMs} />;

  const revision = <CommitHash commitHash={scan.revision} />;

  const numberOfFindings = <>
    <Tooltip title="secrets">
      <Badge badgeContent="s" size="sm">
        <Chip sx={{backgroundColor: 'gray', margin: 0.5}}>
          {scan.numberOfFindingsSecrets}
        </Chip>
      </Badge>
    </Tooltip>
    <Tooltip title="code">
      <Badge badgeContent="c" size="sm">
        <Chip sx={{backgroundColor: 'gray', margin: 0.5}}>
          {scan.numberOfFindingsCode}
        </Chip>
      </Badge>
    </Tooltip>
    <Tooltip title="dependencies">
      <Badge badgeContent="d" size="sm">
        <Chip sx={{backgroundColor: 'gray', margin: 0.5}}>
          {scan.numberOfFindingsDependencies}
        </Chip>
      </Badge>
    </Tooltip>
  </>;

  const risk =
    <Stack>
      <Box>
        <Tooltip title="error">
          <Chip sx={{backgroundColor: config.colors.severity.error, margin: 0.5}}>
            {scan.numberOfFindingsSeverityError}
          </Chip>
        </Tooltip>
        <Tooltip title="warning">
          <Chip sx={{backgroundColor: config.colors.severity.warning, margin: 0.5}}>
            {scan.numberOfFindingsSeverityWarning}
          </Chip>
        </Tooltip>
        <Tooltip title="info">
          <Chip sx={{backgroundColor: config.colors.severity.info, margin: 0.5}}>
            {scan.numberOfFindingsSeverityInfo}
          </Chip>
        </Tooltip>
      </Box>
      {scan.failedDetectors.length !== 0 &&
        <Box>
          Incomplete scan: Detectors {formatArray(scan.failedDetectors, x => x)} failed
        </Box>
      }
    </Stack>;

  const details =
    <Typography level="body-xs">
      <IconButton onClick={() => navigate(`${config.urls.pages.findings}?scanId=${scan.id}`)}>
        <ReadMoreIcon />
      </IconButton>
    </Typography>;

  return [
    scan.id,
    date,
    scan.scannableResourceName,
    scan.branch,
    revision,
    scan.username,
    scan.devicename,
    numberOfFindings,
    risk,
    details,
  ];
}
