import type {ChangeEvent, Dispatch, SetStateAction} from 'react';

export function getSetter(stateSetter: Dispatch<SetStateAction<string>>) {
  return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => stateSetter(e.target.value);
}

// TSOA's implementation of assertNever.
export function assertNever(value: never): never {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

export function isNotNull<T>(v: T | null): v is T {
  return v !== null;
}

export class ShouldNeverHappenError extends Error {
  constructor(message?: string) {
    super(`This should never happen ${message === undefined ? '.' : `:${message}`}.`);
  }
}

export function range(n: number) {
  return [...Array(n).keys()];
}

export function ensureArray<T>(thing: T | T[]): T[] {
  return Array.isArray(thing) ? thing : [thing];
}
