import {Box, Button, Typography} from '@mui/joy';
import {ImportSshHttpsRepository} from './views/ImportSshHttpsRepository';
import {ImportGithubRepo} from './views/ImportGithubRepo';
import type {ScannableResourceRes} from '../../shared/types';
import {isNotNull} from '../../utils/misc';
import {ImportSonarcloudProject} from './views/ImportSonarcloudProject';
import {ImportAzureResource} from './views/ImportAzureResource';
import {ImportSonatypeApplication} from './views/ImportSonatypeApplication';
import {ImportMongodbatlasCluster} from './views/ImportMongodbatlasCluster';

interface Props {
  setTitle: (newTitle: string) => unknown;
  setActiveComponent: (wizardComponent: JSX.Element) => unknown;
  scannableResources: ScannableResourceRes[];
  wizardComponentProps: WizardComponentProps;
}

interface WizardComponentProps {
  onCompletion: () => Promise<unknown>;
}

interface ResourceImportConfig {
  name: string;
  component: JSX.Element;
}

interface ResourceImportConfigGroup {
  name: string;
  resourceImportConfigs: ResourceImportConfig[];
}

export function ResourceTypeSelector({setTitle, setActiveComponent,
  scannableResources, wizardComponentProps}: Props) {
  // TODO: In the case of sonatype, do not close the modal after import
  // but instead instruct the user on how to install the webhook.

  const resourceImportConfigGroups: ResourceImportConfigGroup[] = [
    {
      name: 'Repository / Software',
      resourceImportConfigs: [
        {
          name: 'SSH/HTTPS Repository',
          component: <ImportSshHttpsRepository onCompletion={wizardComponentProps.onCompletion} />,
        },
        {
          name: 'GitHub Repository',
          component: <ImportGithubRepo
            githubRepositories={
              scannableResources.map(scannableResource => scannableResource.githubRepository).filter(isNotNull)
            }
            refetchScannableResources={wizardComponentProps.onCompletion}
          />,
        },
        {
          name: 'Sonarcloud Project',
          component: <ImportSonarcloudProject
            sonarcloudProjects={
              scannableResources.map(scannableResource => scannableResource.sonarcloudProject).filter(isNotNull)
            }
            refetchScannableResources={wizardComponentProps.onCompletion}
          />,
        },
        {
          name: 'Sonatype Application',
          component: <ImportSonatypeApplication
            sonatypeApplications={
              scannableResources.map(scannableResource => scannableResource.sonatypeApplication).filter(isNotNull)
            }
            refetchScannableResources={wizardComponentProps.onCompletion}
          />,
        },
      ],
    },
    {
      name: 'Cloud Resources',
      resourceImportConfigs: [
        {
          name: 'Azure Resource',
          component: <ImportAzureResource
            azureResources={
              scannableResources.map(scannableResource => scannableResource.azureResource).filter(isNotNull)
            }
            refetchScannableResources={wizardComponentProps.onCompletion}
          />,
        },
        {
          name: 'Mongodbatlas Cluster',
          component: <ImportMongodbatlasCluster
            mongodbatlasClusters={
              scannableResources.map(scannableResource => scannableResource.mongodbatlasCluster).filter(isNotNull)
            }
            refetchScannableResources={wizardComponentProps.onCompletion}
          />,
        },
      ],
    },
  ];

  return <>
    <Typography sx={{marginBottom: 2}}>
      Select the type of resource you want to import.
    </Typography>

    <Box>
      {resourceImportConfigGroups.map((resourceImportConfigGroup, iGroup) =>
        <Box key={iGroup} sx={{marginBottom: 1.5}}>
          <Typography level="h4" sx={{marginBottom: 0.2}}>
            {resourceImportConfigGroup.name}
          </Typography>

          {resourceImportConfigGroup.resourceImportConfigs.map((resourceImportConfig, i) =>
            <Box key={i} sx={{marginBottom: 1}}>
              <Button
                onClick={() => {
                  setTitle(`Import ${resourceImportConfig.name}`);
                  setActiveComponent(resourceImportConfig.component);
                }}
              >
                {resourceImportConfig.name}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  </>;
}
