import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  value: boolean;
}

export function BooleanIndicator({value}: Props) {
  return value ? <CheckIcon /> : <ClearIcon />;
}
