export function getErrorMessage(e: unknown): string {
  const candadate0 = (e as ResponseDataError)?.response?.data?.error;
  if(typeof candadate0 === 'string') {
    return candadate0;
  }

  return 'Unknown error';
}

interface ResponseDataError {
  response: {data: {error: string}};
}
