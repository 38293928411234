import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import {MdSearch} from 'react-icons/md';
import {Fragment} from 'react';

interface Props {
  onSearchStringChange?: (arg0: string) => unknown;
  filters?: JSX.Element[];
}

export function Search({onSearchStringChange, filters}: Props) {
  return <>
    <Box
      className="SearchAndFilters-tabletUp"
      sx={{
        borderRadius: 'sm',
        py: 2,
        display: {xs: 'none', sm: 'flex'},
        flexWrap: 'wrap',
        gap: 1.5,
        '& > *': {
          minWidth: {xs: '120px', md: '160px'},
        },
      }}
    >
      <FormControl sx={{flex: 1}} size="sm">
        <FormLabel>Search for order</FormLabel>
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<MdSearch />}
          onChange={e => {
            if(onSearchStringChange === undefined)
              return;

            onSearchStringChange(e.target.value);
          }}
        />
      </FormControl>

      {filters?.map((filter, i) => <Fragment key={i}>{filter}</Fragment>)}
    </Box>
  </>;
}
