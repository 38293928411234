import {App} from './App';
import {PublicClientApplication, EventType} from '@azure/msal-browser';
import {loginRequest, msalConfig} from './authConfig';
import {MsalProvider} from '@azure/msal-react';
import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {accountAtom} from './data/atoms';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

export function AppWrapper() {
  const setAccount = useSetRecoilState(accountAtom);

  useEffect(() => {
    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
      if((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload !== null && 'account' in event.payload && event.payload.account !== null &&
        event.payload.account !== undefined) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);

        msalInstance.acquireTokenSilent({scopes: loginRequest.scopes}).then(authenticationResult => {
          // authenticationResult.accessToken is an access token with a lot of information
          // a shorter token is available via:
          // const {instance: msalInstance} = useMsal();
          // const activeAccount = msalInstance.getActiveAccount();
          // console.log("activeAccount?.idToken");
          // console.log(activeAccount?.idToken);
          // IMPORTANT: the short access token doesn't always work => always use the long one
          console.log(authenticationResult.accessToken);

          setAccount({
            provider: 'microsoft',
            authToken: authenticationResult.accessToken,
          });
        }).catch(console.error);
      }
    });
  }, []);

  return <>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </>;
}
