import Box from '@mui/joy/Box';
import {useSetRecoilState} from 'recoil';
import type {RootOrganizationPostReq} from '../../../shared/types';
import {newNotificationAtom} from '../../../data/atoms';
import {Button, Input} from '@mui/joy';
import {endpoints} from '../../../data/endpoints';
import {useState} from 'react';
import {getErrorMessage} from '../../../errors';
import {ViewPage} from '../../../layout/ViewPage';
import {queryApi} from '../../../data/queries';

export function Registration() {
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [organization, setOrganization] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password0, setPassword0] = useState('');
  const [password1, setPassword1] = useState('');
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

  async function register() {
    const data = {
      name: organization,
      initialUserEmailAddress: emailAddress,
      initialUserPassword: password0,
    } satisfies RootOrganizationPostReq;
    try {
      await queryApi(endpoints.postRootOrganization, undefined, 'post', data);
      setRegistrationSuccessful(true);
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <ViewPage title="Registration">
    <Box>
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="organization"
        value={organization}
        onChange={e => setOrganization(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="email address"
        value={emailAddress}
        onChange={e => setEmailAddress(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="password"
        value={password0}
        onChange={e => setPassword0(e.target.value)}
        type="password"
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="password (confirmation)"
        value={password1}
        onChange={e => setPassword1(e.target.value)}
        type="password"
      />
      <Button
        onClick={register}
        disabled={organization.length === 0 || emailAddress.length === 0 ||
          password0.length === 0 || password0 !== password1 || registrationSuccessful}
      >
        Register
      </Button>
    </Box>

    {registrationSuccessful && 'Please check your emails and confirm the registration.'}
  </ViewPage>;
}
