import {Box, Button, Input} from '@mui/joy';
import {useState} from 'react';
import {queryApi} from '../../../data/queries';
import type {SonarcloudTokenPutReq, WebSessionAuthTokenRes} from '../../../shared/types';
import {endpoints} from '../../../data/endpoints';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountAtom, newNotificationAtom} from '../../../data/atoms';
import {getErrorMessage} from '../../../errors';

const sonarcloudApiKeyPageUrl = 'https://sonarcloud.io/account/security';

interface Props {
  onCompletion: () => Promise<unknown>;
}

export function SonarcloudAuthentication({onCompletion}: Props) {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);
  const setNewNotification = useSetRecoilState(newNotificationAtom);

  const [sonarcloudOrganizationName, setSonarcloudOrganizationName] = useState('');
  const [sonarcloudToken, setSonarcloudToken] = useState('');

  async function authenticate() {
    const data = {
      sonarcloudOrganizationName,
      sonarcloudToken,
    } satisfies SonarcloudTokenPutReq;
    try {
      await queryApi(endpoints.putSonarcloudToken, account, 'put', data);
      await onCompletion();
    } catch(e) {
      setNewNotification(getErrorMessage(e));
    }
  }

  return <>
    <Box>
      To import Sonarcloud projects, please head to <a href={sonarcloudApiKeyPageUrl} target="_blank">{sonarcloudApiKeyPageUrl}</a>, create a new API key, and paste it here. Also make sure to specify your organization's ID correctly (lowercase spelling of the organization's name).
    </Box>
    <Box>
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="sonarcloud organization ID"
        value={sonarcloudOrganizationName}
        onChange={e => setSonarcloudOrganizationName(e.target.value)}
      />
      <Input
        style={{width: 200, marginBottom: 2}}
        placeholder="sonarcloud token"
        value={sonarcloudToken}
        onChange={e => setSonarcloudToken(e.target.value)}
      />
      <Button
        onClick={authenticate}
        disabled={sonarcloudOrganizationName.length === 0 || sonarcloudToken.length === 0}
      >
        Authenticate
      </Button>
    </Box>
  </>;
}
