import {Table} from '../../../components/table/Table';
import type {WebSessionAuthTokenRes, IntegrationRes} from '../../../shared/types';
import {accountAtom, globallySelectedOrganizationItemAtom} from '../../../data/atoms';
import {useIntegrations} from '../../../data/queries';
import {useRecoilValue} from 'recoil';
import type {RowType} from '../../../components/table/ActualTable';
import {ViewPage} from '../../../layout/ViewPage';
import {getOrganizationIdsFromOrganizationItem} from '../../../utils/organizationFiltering';
import {useEffect, useState} from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Button, Chip, Tooltip} from '@mui/joy';
import {config} from '../../../config';
import {IoMdDownload} from 'react-icons/io';
import {AddIntegrationWizard} from '../../../components/addIntegrationWizard/AddIntegrationWizard';

export function Integrations() {
  const account = useRecoilValue<WebSessionAuthTokenRes | null>(accountAtom);

  const globallySelectedOrganizationItem = useRecoilValue(globallySelectedOrganizationItemAtom);
  const {data: integrations, refetch: refetchIntegrations} =
    useIntegrations(account,
      getOrganizationIdsFromOrganizationItem(globallySelectedOrganizationItem));

  const [addIntegrationWizardOpen, setAddIntegrationWizardOpen] = useState(false);

  useEffect(() => {
    refetchIntegrations().catch(console.error);
  }, [globallySelectedOrganizationItem]);

  const topElements = [
    <Button
      style={{marginLeft: 10}}
      color="primary"
      startDecorator={<IoMdDownload />}
      size="sm"
      onClick={() => setAddIntegrationWizardOpen(true)}
    >
      New
    </Button>,
  ];

  return <ViewPage title="Integrations" topElements={topElements}>
    <Table
      columnTitles={['id', 'Scope', 'Hoster', 'Resources', 'Creator', 'Actions']}
      rows={(integrations ?? []).map(integration => getIntegrationRow(integration))}
    />

    {addIntegrationWizardOpen &&
      <AddIntegrationWizard
        closeWizard={() => setAddIntegrationWizardOpen(false)}
        refetchIntegrations={refetchIntegrations}
      />
    }
  </ViewPage>;
}

function hosterToNumber(hoster: string) {
  switch(hoster) {
    case 'github':
      return 0;
    case 'sonarcloud':
      return 1;
    case 'sonatype':
      return 2;
    case 'azure':
      return 3;
    case 'mongodbatlas':
      return 4;
    default:
      throw new Error('unexpected hoster');
  }
}

function getIntegrationRow(integration: IntegrationRes): RowType {
  const virtualId = integration.id * 100 + hosterToNumber(integration.hoster);

  const resources = <>
    {integration.resources.map((resource, i) =>
      <Chip key={i} sx={{backgroundColor: config.colors.chips.scannableResource, margin: 0.5}}>
        {resource}
      </Chip>
    )}
  </>;

  const deleteButton =
    <Tooltip title="delete">
      <DeleteForeverIcon />
    </Tooltip>;

  return [
    virtualId,
    integration.scope,
    integration.hoster,
    resources,
    integration.creator,
    deleteButton,
  ];
}
