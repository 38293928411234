import {Search} from './Search';
import type {RowType} from './ActualTable';
import {ActualTable} from './ActualTable';
import {PaginationBar} from './PaginationBar';

interface Props {
  filters?: JSX.Element[];
  columnTitles: string[];
  rows: RowType[];
  idHighlightedRow?: number;
  idsInitiallySelectedRows?: number[];
  onSearchStringChange?: (arg0: string) => unknown;
  onRowCheckboxChange?: (rowId: number, checked: boolean) => unknown;
  pagination?: {
    pageNumber: number;
    pageSize: number;
    totalNumberOfItems: number;
    setPageNumber: (n: number) => unknown;
  };
}

export function Table({filters, columnTitles, rows, idHighlightedRow, idsInitiallySelectedRows,
  onSearchStringChange, onRowCheckboxChange, pagination}: Props) {
  if(!rows.every(row => row.length === columnTitles.length))
    throw new Error('row lengths do not match column titles length');

  if(columnTitles[0] !== 'id')
    throw new Error('first column must be id');

  return (
    <>
      <Search
        filters={filters}
        onSearchStringChange={onSearchStringChange}
      />
      <ActualTable
        columnTitles={columnTitles}
        rows={rows}
        idHighlightedRow={idHighlightedRow}
        idsInitiallySelectedRows={idsInitiallySelectedRows}
        onRowCheckboxChange={onRowCheckboxChange}
      />
      {pagination !== undefined &&
        <PaginationBar
          pageNumber={pagination.pageNumber}
          pageSize={pagination.pageSize}
          totalNumberOfItems={pagination.totalNumberOfItems}
          setPageNumber={pagination.setPageNumber}
        />
      }
    </>
  );
}
