import List from '@mui/joy/List';
import {Fragment} from 'react';
import {ensureArray} from '../../utils/misc';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function SidebarListPartition({children}: Props) {
  return <>
    <List
      size="sm"
      sx={{
        '--List-nestedInsetStart': '30px',
        '--ListItem-radius': (theme) => theme.vars.radius.sm,
      }}
    >
      {ensureArray(children).map((child, i) =>
        <Fragment key={i}>
          {child}
        </Fragment>
      )}
    </List>
  </>;
}
